package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.textView
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.px
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.withLoadingAndError


/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/06/11.
 */
class ReorderRideDialog(
    private val extendedRide: ExtendedRideDto,
    private val reloadRides: () -> Unit,
    private val onNextPassengerClicked: (() -> Unit)
) {
    private val popup by lazy { popUpDialog() }
    private val viewModel = ReorderRideViewModel(extendedRide)
    fun show() {
        theme.showDialog(popup) {
            viewModel.calculatePrices()
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Reorder".localized()
                        onClick = {
                            reorder()
                        }
                    }.withLoadingAndError(viewModel.reorderRideUiState, onLoaded = {
                        popup.dismissDialog()
                        val newRideId = viewModel.newRideId
                        if (newRideId != null) {
                            RideDetailsDialog(newRideId, null, reloadRides = reloadRides,true, onNextPassengerClicked =
                            onNextPassengerClicked).show()
                        }
                    }, onRetryClicked = {
                        reorder()
                    })

                }
            }
            if (extendedRide.ride.destLat == null){
                viewModel.pricesUiState.value = BasicUiState.Loaded
            }
            withLoadingAndError(viewModel.pricesUiState, onLoaded =
            {
                verticalLayout {

                    style { width = matchParent }
                    theme.bigTextView(this) {
                        text = "Ride Details".localized()
                    }
                    verticalFiller(4)
                    theme.text(this) {
                        text = extendedRide.ride.bookingKey.toString()
                    }
                    verticalFiller(16)
                    horizontalLayout {
                        style {
                            width = matchParent
                            paddingEnd = 30.px
                        }
                        verticalLayout {
                            style { weightOf(1) }
                            theme.label(this, "PickUp: ".localized(), false)
                            theme.mediumTextView(this) {
                                text = extendedRide.ride.pickupAddress
                            }
                        }
                        horizontalFiller()
                        verticalLayout {
                            style { weightOf(1) }
                            theme.label(this, "Destination: ".localized(), false)
                            theme.mediumTextView(this) {
                                text = extendedRide.ride.destAddress ?: "Open Ride".localized()
                            }
                        }
                    }
                    horizontalLayout {
                        style {
                            width = matchParent
                            paddingEnd = 30.px
                        }
                        val phoneNumber = if (extendedRide.ridePhoneNumber != null) {
                            "${extendedRide.ridePhoneNumber?.callingCode} ${extendedRide.ridePhoneNumber?.phone}"
                        } else extendedRide.passenger.phone
                        verticalLayout {
                            style { weightOf(1) }
                            theme.label(this, "Phone Number: ".localized(), false)
                            theme.mediumTextView(this) {
                                text = phoneNumber
                            }
                        }
                        horizontalFiller()
                    }
                    verticalFiller(15)
                    extendedRide.carType?.let { carType ->
                        viewModel.newRideExtendedPrice?.let { price ->
                            mount(
                                PriceDisplayItem(
                                    carType,
                                    price
                                )
                            )
                        }
                    }
                    if (extendedRide.ride.destLat == null) {
                        horizontalLayout {
                            style {
                                width = matchParent
                                alignItems = Alignment.Center
                                padding = 24.px
                            }
                            textView {
                                text = "Price will be determined at the end.".localized()
                                style {
                                    color = AppColors.textLight
                                }
                            }
                        }
                    }

                }
            }, onRetryClicked = {
                viewModel.calculatePrices()
            })
        }

    }

    fun reorder() {
        viewModel.reorderRide()
    }
}