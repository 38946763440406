package com.narbase.letsgo.models.audit

import com.narbase.letsgo.dto.EnumDtoName
import com.narbase.letsgo.dto.core.EnumPersistenceName

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2023/05/22.
 */

enum class AuditLogEventType(persistenceName: String? = null, dtoName: String? = null) : EnumPersistenceName, EnumDtoName {
    SystemAccess,
    Profile,

    Staff,
    Drivers,
    DriverProfileGroups,
    DynamicRoles,
    PassengerProfileGroups,
    CurrencyIncentiveConfig,
    RideUpdate,
    OrderingIncentive,
    Target
    ;

    override val dtoName = dtoName ?: this.name
    override val persistenceName = persistenceName ?: this.name
}