package com.narbase.letsgo.web.dto.ridephonenumber
data class RidePhoneNumberDto(
    val id: Long?,
    val rideId: Long,
    val callingCode: String,
    val phone: String,
)

data class PhoneNumberDto(
    val callingCode: String,
    val phone: String,
)