package com.narbase.letsgo.web.views.driverTargets

import com.narbase.kunafa.core.components.TextInput
import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.dto
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.dto.driverprofilegroup.DriverProfileGroupDto
import com.narbase.letsgo.web.dto.drivertargets.ExtendedTargetDto
import com.narbase.letsgo.web.dto.drivertargets.MessagesDto
import com.narbase.letsgo.web.dto.drivertargets.TargetDto
import com.narbase.letsgo.web.dto.drivertargets.TargetResultsDto
import com.narbase.letsgo.web.models.PrizeType
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.driverprofilegroup.GetDriverProfileGroupsEndpoint
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.*
import com.narbase.letsgo.web.utils.datetime.DateTimeDto
import com.narbase.letsgo.web.utils.datetime.dto
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.views.*
import kotlin.js.Date
import kotlin.time.Duration.Companion.milliseconds

class UpsertTargetDialog(
    private val extendedTarget: ExtendedTargetDto?,
    val onUpdated: () -> Unit
) {
    private val viewModel = TargetsManagementViewModel()
    private val popup by lazy { popUpDialog() }

    private val errorMessage = "Fill all fields".localized()

    var amountInput: TextInput? = null
    var budgetInput: TextInput? = null
    var periodInMinutesInput: TextInput? = null
    var noOfRidesInput: TextInput? = null
    var prizeTypeDropDownList: RemoteDropDownList<PrizeType>? = null
    var selectedPrizeType: Observable<PrizeType?> = Observable()
    var selectedProfileGroup: DriverProfileGroupDto? = null
    var startTimeInput: TextInput? = null
    var endTimeInput: TextInput? = null
    val isAutoRepeatSelected: Observable<Boolean> = Observable()
    val allowMultiAchievementSelected: Observable<Boolean> = Observable()
    var profileGroupsDropDownList: RemoteDropDownList<DriverProfileGroupDto>? = null
    var errorTextView: TextView? = null
    var isAutoRepeatSwitch: ToggleSwitch? = null
    var allowMultiAchievementSwitch: ToggleSwitch? = null
    var profileGroupTextView: TextView? = null
    var prizeTextView: TextView? = null
    var isAmountHiddenSwitch: ToggleSwitch? = null
    val isAmountHiddenSelected: Observable<Boolean> = Observable()
    var newTargetMessageInput: TextInput? = null
    var prizeMessageInput: TextInput? = null
    val isPrioritySelected: Observable<Boolean> = Observable()
    var isPrioritySwitch: ToggleSwitch? = null


    private val isStarted = extendedTarget != null && extendedTarget.target.starTime.milliSeconds < Date.now()
    private val isTimeEnded = extendedTarget != null && extendedTarget.target.endTime.milliSeconds < Date.now()
    private val isCompleted =
        isTimeEnded && (extendedTarget?.target?.budget != null || extendedTarget?.target?.prizeType?.enum() == PrizeType.exact)
    val isAwaitingBudget = isTimeEnded && extendedTarget?.target?.budget == null


    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    horizontalLayout {
                        style {
                            width = wrapContent
                        }
                        isVisible = !isCompleted

                        theme.mainButton(this) {
                            text = "Save".localized()
                            onClick = {
                                save()
                            }
                        }.withLoadingAndError(viewModel.updateUiState, onLoaded = {
                            popup.dismissDialog()
                            onUpdated.invoke()
                        }, onRetryClicked = {
                            save()
                        })
                    }
                }
            }
            verticalLayout {
                style {
                    height = wrapContent
                    width = 40.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                horizontalLayout {
                    style {
                        width = matchParent
                        paddingEnd = 30.px
                    }
                    theme.mediumTextView(this) {
                        text = "Target".localized()
                    }
                    horizontalFiller()
                }
                verticalFiller(20)
                targetForm()
            }
        }
    }

    private fun LinearLayout.profileGroup() = verticalLayout {
        style {
            width = matchParent
        }
        theme.simpleLabel(this) {
            text = "Driver Profile Group".localized()
        }
        verticalLayout {
            style {
                width = matchParent
                isVisible = extendedTarget == null
            }
            profileGroupsDropDownList = setupDropDownList(
                "Driver Profile Group".localized(),
                getList = { pageNo, pageSize, searchTerm ->
                    try {
                        val dto = GetDriverProfileGroupsEndpoint.Request(pageNo, pageSize, searchTerm)
                        GetDriverProfileGroupsEndpoint.remoteProcess(dto).data.list.map { it.driverProfileGroup }
                            .toTypedArray()
                    } catch (t: Throwable) {
                        t.printStackTrace()
                        arrayOf()
                    }
                },
                itemToString = { it.name },
                onItemSelected = {
                    selectedProfileGroup = it
                },
                defaultItem = extendedTarget?.driverProfileGroup,
                viewWidthFactory = { matchParent },
                showAutoComplete = true
            )
        }

        profileGroupTextView = theme.mediumTextView(this) {
            text = extendedTarget?.driverProfileGroup?.name ?: ""
            isVisible = extendedTarget?.driverProfileGroup != null
        }
    }

    private fun LinearLayout.targetForm() = verticalLayout {
        style {
            width = matchParent
        }
        val target = extendedTarget?.target
        selectedPrizeType.value = target?.prizeType?.enum()
        profileGroup()
        theme.simpleLabel(this) {
            text = "Number of Rides".localized()
        }
        noOfRidesInput = theme.textInput(this) {
            style {
                width = matchParent
            }
            type = "number"
            text = target?.noOfRides?.toString() ?: ""
        }
        val isPriority = extendedTarget?.target?.isPriority ?: false
        isPrioritySwitch = switch("Priority", isPriority, isSelected = isPrioritySelected)
        theme.simpleLabel(this) {
            text = "Prize Type".localized()
        }
        verticalLayout {
            style {
                width = matchParent
                isVisible = extendedTarget == null
            }
            prizeTypeDropDownList = setupDropDownList(
                "Prize Type",
                getList = { _, _, _ ->
                    PrizeType.values()
                },
                itemToString = { it.dtoName },
                onItemSelected = {
                    selectedPrizeType.value = it
                },
                defaultItem = selectedPrizeType.value,
                viewWidthFactory = { matchParent },
                showAutoComplete = true,

                )
        }

        prizeTextView = theme.mediumTextView(this) {
            text = target?.prizeType?.enum()?.dtoName ?: ""
            isVisible = extendedTarget != null
        }
        verticalLayout {
            style {
                width = matchParent
            }
            selectedPrizeType.observe { prizeType ->
                clearAllChildren()
                verticalLayout {
                    style {
                        width = matchParent
                        isVisible = if (target != null) {
                            target.prizeType.enum() == PrizeType.exact
                        } else prizeType == PrizeType.exact
                    }
                    theme.simpleLabel(this) {
                        text = "Prize Amount".localized()
                    }
                    amountInput = theme.textInput(this) {
                        style {
                            width = matchParent
                        }
                        type = "number"
                        text = target?.amount?.toString() ?: ""
                        if (target != null && target.starTime.milliSeconds < Date.now()) {
                            setDisabled()
                        }
                    }
                    val isAmountHidden = extendedTarget?.target?.isAmountHidden ?: false
                    isAmountHiddenSwitch =
                        switch("Is Amount Hidden ".localized(), isAmountHidden, isSelected = isAmountHiddenSelected)

                    if (isStarted) {
                        isAmountHiddenSwitch?.disable()
                    }

                }
                verticalLayout {
                    style {
                        width = matchParent
                        isVisible = if (target != null) {
                            target.prizeType.enum() == PrizeType.share
                        } else prizeType == PrizeType.share
                    }

                    theme.simpleLabel(this) {
                        text = "Prize Budget".localized()
                    }
                    budgetInput = theme.textInput(this) {
                        style {
                            width = matchParent
                        }
                        type = "number"
                        text = target?.budget.toString()
                        if (target != null && target.endTime.milliSeconds < Date.now() && target.budget != null) {
                            setDisabled()
                        }

                    }
                }
            }
        }
        theme.simpleLabel(this) {
            text = "Start Time".localized()
        }
        startTimeInput = theme.textInput(this) {
            style {
                width = matchParent
            }
            type = "datetime-local"
            text = target?.starTime?.toDate()?.formatToInputElement() ?: ""
            onChange = {
                fillEndTime()
            }
        }
        theme.simpleLabel(this) {
            text = "End Time".localized()
        }
        endTimeInput = theme.textInput(this) {
            style {
                width = matchParent
            }
            type = "datetime-local"
            text = target?.endTime?.toDate()?.formatToInputElement() ?: ""
        }
        val isAutoRepeat = extendedTarget?.target?.isAutoRepeat ?: true
        isAutoRepeatSwitch = switch("Auto Repeat", isAutoRepeat, isSelected = isAutoRepeatSelected)
        val allowMultiAchievement = extendedTarget?.target?.allowMultiAchievements ?: true
        allowMultiAchievementSwitch =
            switch(
                "Allow Multiple Achievement".localized(),
                allowMultiAchievement,
                isSelected = allowMultiAchievementSelected
            )

        messagesLayout(extendedTarget?.target?.messages)

        errorTextView = theme.mediumTextView(this) {
            style { color = Color.red }
        }
        val results = extendedTarget?.target?.results
        verticalFiller(10)
        if (results != null) {
            resultsLayout(results)
        }
        disableFormInput()


    }

    private fun LinearLayout.messagesLayout(messages: MessagesDto?) = verticalLayout {
        matchParentWidth()
        theme.mediumTextView(this) {
            text = "Messages".localized()
        }
        theme.mediumTextView(this) {
            style {
                fontSize = AppFontSizes.smallerText
            }
            text = "Enter messages to replace default messages in target settings if needed.".localized()
        }
        theme.simpleLabel(this) {
            text = "New Target message".localized()
        }
        newTargetMessageInput = theme.textInput(this) {
            matchParentWidth()
            text = messages?.newTargetMessage ?: ""
        }
        theme.simpleLabel(this) {
            text = "Prize message".localized()
        }
        prizeMessageInput = theme.textInput(this) {
            matchParentWidth()
            text = messages?.prizeMessage ?: ""
        }
    }

    private fun LinearLayout.resultsLayout(results: TargetResultsDto) = verticalLayout {
        matchParentWidth()
        style {
            backgroundColor = AppColors.lightBackground
            padding = 10.px
        }
        theme.mediumTextView(this) {
            text = "Results".localized()
        }
        horizontalLayout {
            theme.label(this, "Number Of Winners".localized(), false)
            theme.mediumTextView(this) {
                text = results.noOfWinners.toString()
            }
        }
        horizontalLayout {
            theme.label(this, "Amount Per Winner".localized(), false)
            theme.mediumTextView(this) {
                text = results.amountPerWin.toString()
            }
        }
    }

    private fun fillEndTime() {
        var startTime = startTimeInput?.text
        if (!startTime.isNullOrBlank()) {
            val endTimeText = startTime
            endTimeInput?.element?.min = endTimeText
        }
    }


    private fun disableFormInput() {

        val target = extendedTarget?.target
        if (target != null) {
            if (isStarted) {   //started
                amountInput?.setDisabled()
                periodInMinutesInput?.setDisabled()
                startTimeInput?.setDisabled()
                endTimeInput?.setDisabled()
                noOfRidesInput?.setDisabled()
                if (target.prizeType.enum() == PrizeType.exact) {
                    allowMultiAchievementSwitch?.disable()
                    isAmountHiddenSwitch?.disable()
                }
                if (isAwaitingBudget && target.prizeType.enum() == PrizeType.share) {
                    newTargetMessageInput?.setDisabled()
                }
                if (isCompleted && target.prizeType.enum() == PrizeType.share) {   //ended and budget assigned
                    allowMultiAchievementSwitch?.disable()
                    isAutoRepeatSwitch?.disable()
                    prizeMessageInput?.setDisabled()
                    newTargetMessageInput?.setDisabled()
                    isPrioritySwitch?.disable()
                } else if (isCompleted && target.prizeType.enum() == PrizeType.exact) {
                    isPrioritySwitch?.disable()
                    isAutoRepeatSwitch?.disable()
                    prizeMessageInput?.setDisabled()
                    newTargetMessageInput?.setDisabled()
                }
            }
        }
    }

    private fun LinearLayout.switch(
        title: String,
        value: Boolean,
        isSelected: Observable<Boolean>
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        horizontalLayout {
            switch.resetIsSelected()
            switch.isSelected.value = value
            switch.isSelected.observe {
                if (it != null) {
                    isSelected.value = it
                }
            }
            theme.label(this, title, false)
            mount(switch)
        }
        return switch
    }

    fun Date.formatToInputElement(): String {
        val month = (getMonth() + 1)
        val monthText = if (month < 10) {
            "0$month"
        } else {
            month.toString()
        }
        val hour = (getHours())
        val hourText = if (hour < 10) {
            "0$hour"
        } else {
            hour.toString()
        }
        val date = (getDate())
        val dateText = if (date < 10) {
            "0$date"
        } else {
            date.toString()
        }
        val mins = (getMinutes())
        val minsText = if (mins < 10) {
            "0$mins"
        } else {
            mins.toString()
        }
        return getFullYear().toString() + "-" + monthText + "-" + dateText + "T" + hourText + ":" + minsText
    }

    private fun String.dateFromInputElement(): Date? {
        val list = split("T")
        if (list.size > 1) {
            return (list[0] + " " + list[1] + ":00").toDate()
        }
        return null
    }

    private fun save() {

        var amount: Double? = null
        var budget: Double? = null
        var periodInMinutes = 0
        val prizeType = selectedPrizeType.value?.dto() ?: PrizeType.exact.dto()
        var noOfRides = 0
        val startTime: DateTimeDto?
        val endTime: DateTimeDto?
        val isAutoRepeat: Boolean?
        val allowMultiAchievements: Boolean?
        val isAmountHidden: Boolean = isAmountHiddenSelected.value ?: false
        val driverProfileGroupId: Int? = selectedProfileGroup?.id
        var newTargetMessage: String? = null
        var prizeMessage: String? = null
        var isPriority: Boolean = isPrioritySelected.value ?: false
        if (driverProfileGroupId == null) {
            errorTextView?.text = "Driver Profile Group is required".localized()
            return
        }
        if (selectedPrizeType.value == PrizeType.exact) {
            if (amountInput?.text.isNullOrBlank()) {
                errorTextView?.text = "Prize amount is required when Prize type is Exact.".localized()
                return
            } else {
                amount = amountInput?.text?.toDouble()

            }
        }
        if (budgetInput?.text?.toDoubleOrNull() != null && selectedPrizeType.value == PrizeType.share) {
            budget = budgetInput?.text?.toDouble()
        }

        val noOfRideText = noOfRidesInput?.text
        if (noOfRideText.isNullOrBlank()) {
            errorTextView?.text = "Number of rides  is required.".localized()
            return
        } else if (noOfRideText.toIntOrNull() != null) {
            noOfRides = noOfRideText.toInt()

        }

        if (extendedTarget != null && isStarted) {
            startTime = extendedTarget.target.starTime
            endTime = extendedTarget.target.endTime
        } else {
            if (startTimeInput?.text.isNullOrBlank()) {
                errorTextView?.text = "start time is required".localized()
                return
            } else {
                startTime = startTimeInput?.text?.dateFromInputElement()?.dto() ?: return
            }

            if (endTimeInput?.text.isNullOrBlank()) {
                errorTextView?.text = "end time is required".localized()
                return
            } else {
                endTime = endTimeInput?.text?.dateFromInputElement()?.dto() ?: return
                if (endTime.milliSeconds < startTime.milliSeconds) {
                    errorTextView?.text = "end time mustn't be earlier than start time".localized()
                    return
                }
            }
        }

        if (isAutoRepeatSelected.value == null) {
            errorTextView?.text = "isAutoRepeat is required".localized()
            return
        } else {
            isAutoRepeat = isAutoRepeatSelected.value ?: return
        }

        if (allowMultiAchievementSelected.value == null) {
            errorTextView?.text = "allowMultiAchievement is required".localized()
            return
        } else {
            allowMultiAchievements = allowMultiAchievementSelected.value ?: return
        }

        if (!newTargetMessageInput?.text?.trim().isNullOrBlank()) {
            newTargetMessage = newTargetMessageInput?.text
        }

        if (!prizeMessageInput?.text?.trim().isNullOrBlank()) {
            prizeMessage = prizeMessageInput?.text
        }

        val diff = endTime.milliSeconds - startTime.milliSeconds

        periodInMinutes = diff.milliseconds.inWholeMinutes.toInt()

        val dto = TargetDto(
            id = extendedTarget?.target?.id,
            driverProfileGroupId = driverProfileGroupId,
            periodInMinutes = periodInMinutes,
            amount = amount,
            budget = budget,
            noOfRides = noOfRides,
            prizeType = prizeType,
            results = extendedTarget?.target?.results,
            starTime = startTime,
            endTime = endTime,
            isAutoRepeat = isAutoRepeat,
            isRepeatOf = extendedTarget?.target?.isRepeatOf,
            allowMultiAchievements = allowMultiAchievements,
            isAmountHidden = isAmountHidden,
            messages = MessagesDto(newTargetMessage, prizeMessage),
            isPriority = isPriority
        )
        viewModel.upsertTarget(dto, extendedTarget?.target?.isAutoRepeat ?: true)
    }


}
