package com.narbase.letsgo.web.views.callCenter.selectedpassenger

import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.models.PhoneNumberAvailabilityStatus
import com.narbase.letsgo.web.models.PhoneNumberType
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.user.profile.EditablePhoneNumberView

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/10/31.
 */
class EditPhoneNumbersDialog(
    private val passengerPhoneNumbers: List<PassengerPhoneNumberDto>,
    val passenger: PassengerDto,
    val onEdited: () -> Unit,
    val isSelectable: Boolean,
    val onSelected: (PhoneNumberDto) -> Unit,
    val currentPhoneNumber: PhoneNumberDto?
) {
    private val popup by lazy { popUpDialog() }
    private val viewModel = EditPhoneNumbersViewModel()
    val addNewUiState = Observable<BasicUiState>()

    private var mainPhoneNumberView : LinearLayout? = null
    private var selectedPhoneNumber: Observable<PhoneNumberDto> = Observable()
    var selectedPhoneNumberView : EditPassengerPhoneNumberComponent? = null

    val passengerId = passenger.id
    private val phoneNumberFields: MutableList<EditPassengerPhoneNumberComponent> =
        if (passengerId != null) {
            passengerPhoneNumbers.map {

                EditPassengerPhoneNumberComponent(
                    it, passengerId,
                    isInvalid = {
                        onInvalid(it)
                    },
                    onRemove = { editableComponent ->
                        val phoneNumberId = editableComponent.phoneNumber?.id
                        if (phoneNumberId != null) {
                            deletePhoneNumber(phoneNumberId)
                            onRemoved(editableComponent)
                        }

                    },
                    isSelectable = isSelectable,
                    onSelected = { view,  phoneNumber->
                        onPhoneFieldSelected(view, phoneNumber)
                    }

                    )
            }.toMutableList()
        } else mutableListOf()


    var errorTextView: TextView? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    if (!isSelectable) {
                        horizontalFiller(10)
                        theme.mainButton(this) {
                            text = "Save".localized()
                            isVisible = false //!isSelectable
                            onClick = {
                                save()
                            }
                        }.withLoadingAndError(viewModel.saveRideUiState, onLoaded = {
                            if (viewModel.phoneNumberAvailability?.availabilityStatus?.enum() == PhoneNumberAvailabilityStatus.Unavailable) {
                                val unavailablePhoneNumber =
                                    viewModel.phoneNumberAvailability?.unavailablePhoneNumber ?: return@withLoadingAndError
                                val existingPassenger =
                                    viewModel.phoneNumberAvailability?.phoneNumberOwner ?: return@withLoadingAndError
                                showUnAvailablePhoneNumberError(unavailablePhoneNumber, existingPassenger)

                            } else {
                                onEdited.invoke()
                                popup.dismissDialog()
                            }

                        }, onRetryClicked = {
                            save()
                        })

                    }

                    horizontalFiller(10)
                    verticalLayout {
                        selectedPhoneNumber.observe {phoneNumber ->
                            clearAllChildren()
                            if (phoneNumber != null ){
                                theme.mainButton(this) {
                                    text = "Select for Ride & Save ".localized()
                                    onClick = {

                                        save()
                                    }
                                }.withLoadingAndError(viewModel.saveRideUiState, onLoaded = {
                                    if (viewModel.phoneNumberAvailability?.availabilityStatus?.enum() == PhoneNumberAvailabilityStatus.Unavailable) {
                                        val unavailablePhoneNumber =
                                            viewModel.phoneNumberAvailability?.unavailablePhoneNumber ?: return@withLoadingAndError
                                        val existingPassenger =
                                            viewModel.phoneNumberAvailability?.phoneNumberOwner ?: return@withLoadingAndError
                                        showUnAvailablePhoneNumberError(unavailablePhoneNumber, existingPassenger)

                                    } else {
                                        onEdited.invoke()
                                        val updatedPhoneNumber = selectedPhoneNumberView?.getPhoneNumber() ?: phoneNumber
                                        onSelected(updatedPhoneNumber)
                                        popup.dismissDialog()
                                    }

                                }, onRetryClicked = {
                                    save()
                                })

                            }

                        }
                    }

                }
            }

            verticalLayout {

                style { width = matchParent }
                horizontalLayout {
                    matchParentWidth()
                    theme.bigTextView(this) {
                        text = "Other Phone Numbers".localized()
                    }
                    horizontalFiller()
                    theme.outlineButton(this) {
                        text = "+ Add".localized()
                        onClick = {
                            addNewPhoneField()
                        }
                    }
                }

                verticalFiller(16)

                verticalLayout {
                    matchParentWidth()

                    phoneNumbersLayout()


                    withLoadingAndError(addNewUiState,
                        onLoaded = {
                            clearAllChildren()
                            phoneNumbersLayout()
                            addNewUiState.value = null
                        },
                        onRetryClicked = {})
                }

                errorTextView = theme.errorText(this) {
                }

                verticalFiller(15)

            }

        }

    }

    fun LinearLayout.phoneNumbersLayout() = verticalLayout {
        mainPhoneNumberLayout()
        matchParentWidth()
        theme.label(this, "Additional phone numbers".localized(), false)
        phoneNumberFields.forEach {
            mount(it)
            if (it.phoneNumber?.callingCode?.trim() == currentPhoneNumber?.callingCode && it.phoneNumber?.phone?.trim() == currentPhoneNumber?.phone ) {
                it.setSelected()
            }
        }


    }

    private fun showUnAvailablePhoneNumberError(
        phoneNumberDto: PassengerPhoneNumberDto,
        extendedPassengerDto: ExtendedPassengerDto
    ) {
        val errorMessage =
            "Phone number already belongs to another passenger\nOwner: ${extendedPassengerDto.passenger.fullName}".localized()
        errorTextView?.text = errorMessage


    }

    private fun addNewPhoneField() {
        passengerId ?: return
        phoneNumberFields.add(
            EditPassengerPhoneNumberComponent(null, passengerId, isInvalid = {
                onInvalid(it)
            }, isSelectable = isSelectable,
                onRemove = { editableComponent ->
                    onRemoved(editableComponent)
                },
                onSelected = { view, phoneNumber ->
                    onPhoneFieldSelected(view, phoneNumber) }
            )
        )
        addNewUiState.value = BasicUiState.Loaded
    }

    fun onPhoneFieldSelected(editPassengerPhoneNumberComponent: EditPassengerPhoneNumberComponent?, phoneNumber: PhoneNumberDto) {
        phoneNumberFields.filter {it != editPassengerPhoneNumberComponent  }.forEach {
            it.setNotSelected()
        }
        mainPhoneNumberView?.addRuleSet(
            classRuleSet {
                backgroundColor = if (editPassengerPhoneNumberComponent == null) {
                    AppColors.hoverBackground
                } else {
                    Color.white
                }
            }
        )
        selectedPhoneNumber.value = phoneNumber
        selectedPhoneNumberView = editPassengerPhoneNumberComponent

    }

    private fun onInvalid(errorMessage: String) {
        errorTextView?.text = errorMessage
    }

    private fun onRemoved(editableComponent: EditPassengerPhoneNumberComponent) {
        selectedPhoneNumber.value = null

        phoneNumberFields.remove(editableComponent)
        addNewUiState.value = BasicUiState.Loaded
    }

    private fun hasDuplicatePhoneNumbers(phoneNumbers: List<PassengerPhoneNumberDto>): Boolean {
        return phoneNumbers.size != phoneNumbers.distinctBy { it.callingCode + it.phone }.size
    }


    fun save() {
        val p = phoneNumberFields.map {
            val phoneNumber = it.getInfo() ?: return
            phoneNumber
        }
        if (hasDuplicatePhoneNumbers(p)) {
            onInvalid("Phone numbers are duplicates".localized())
            return
        }
        viewModel.savePhoneNumbers(p)
    }

    fun deletePhoneNumber(phoneNumberId: Long) {
        showConfirmationDialogWithRemoteAction(
            "Delete Phone Number".localized(),
            "Are you sure you want to delete this phone number?\nThis action cannot be reversed.".localized(),
            actionButtonText = "Delete".localized(),
            action = {
                viewModel.deletePhoneNumber(phoneNumberId)
            },
            uiState = viewModel.deletePhoneNumberUiState,
            onConfirmed = {},
            actionButtonStyle = null,

            )
    }

    private fun LinearLayout.mainPhoneNumberLayout() = verticalLayout {
        matchParentWidth()
        theme.label(this, "Main phone number".localized(), false)

        mainPhoneNumberView = horizontalLayout {
            val (callingCode, phone) = passenger.phone.separatePhoneNumber()
            style {
                width = matchParent
                padding = 10.px
                margin = 10.px
                alignItems = Alignment.Center
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 8.px
                if (callingCode == currentPhoneNumber?.callingCode && phone == currentPhoneNumber.phone ) {
                    backgroundColor = AppColors.hoverBackground
                }

                if (isSelectable) {
                    hover {
                        backgroundColor = AppColors.hoverBackground
                        pointerCursor()
                    }
                }

            }

            theme.mediumTextView(this ) {
                text = "+$callingCode $phone"
            }
            horizontalFiller()
            onClick = {
                addRuleSet(
                    classRuleSet {
                        backgroundColor = AppColors.hoverBackground
                    }
                )
                onPhoneFieldSelected(null, PhoneNumberDto(callingCode, phone))
            }
        }
    }


    fun String.separatePhoneNumber(): Pair<String, String> {
        if (length > 4) {
            if (startsWith("+")) {
                val callingCode = substring(1, 4)
                val phone = substring(4)
                return callingCode to phone
            } else {
                val callingCode = substring(0, 3)
                val phone = substring(3)
                return callingCode to phone

            }
        }

        return "" to ""
    }


}