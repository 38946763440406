package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName


enum class PhoneNumberAvailabilityStatus(dtoName: String? = null) : EnumDtoName {
    Available,
    Unavailable,
    ;

    override val dtoName = dtoName ?: this.name
}