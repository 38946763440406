package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.dto.price.PriceDataDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.roundToTwoDigits
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.callCenter.pointselection.PointSelectorDialog
import org.w3c.dom.Element
import kotlin.js.json

class RequestRideComponent(
    val passengerDto: PassengerDto,
    val reloadRides: ()->Unit,
    val extendedRide: ExtendedRideDto? = null,
    val saveUpdateLocations: ((pickup:LocationPointDto, destination: LocationPointDto?, priceToken: String?, payablePrice: Int?, currencyName: String?) -> Unit)? = null,
    val onRideCreated: () -> Unit?
) : Component(
) {
    private val viewModel = RequestRideViewModel(passengerDto)
    private var selectPickup: Observable<LocationPointDto?> = Observable()
    private var selectDest: Observable<LocationPointDto?> = Observable()
    var noteInput: TextInput? = null
    val ridePromoCodeComponent = RidePromoCodeDisplayComponent(passengerDto.id!!, onActivePromoCodeChanged = {
        calculatePrice()
    })
    var carTypeLayoutItem: LinearLayout? = null
    val isEdit = extendedRide != null

    var pointSelectorDialog : PointSelectorDialog? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getFavoritePoints()
        viewModel.getLatestRide()
    }

    fun setFieldsForEditable() {
        val extendedRideDto = extendedRide ?: return
        selectPickup.value = LocationPointDto(
            name = "",
            address = extendedRideDto.ride.pickupAddress,
            lat = extendedRideDto.ride.pickupLat,
            lng = extendedRideDto.ride.pickupLng,
            pointOfInterestDto = null,
            favoritePointDto = null
        )
        val destLat = extendedRideDto.ride.destLat
        val destLng = extendedRideDto.ride.destLng
        if (destLat != null && destLng != null)
            selectDest.value = LocationPointDto(
                name = "",
                address = extendedRideDto.ride.destAddress,
                lat = destLat,
                lng = destLng,
                pointOfInterestDto = null,
                favoritePointDto = null
            )
        val carType = extendedRide.carType
        if (carType != null) {
            viewModel.carTypes = listOf(carType)
            carTypeLayoutItem?.apply {
                horizontalLayout {
                    style {
                        width = matchParent
                        padding = 15.px
                        margin = 2.px
                        justifyContent = JustifyContent.Center
                    }
                    theme.text(this, ){
                        text = "Change pick up or destination, then Get New Price"
                    }
                }
            }
        }
    }
    override fun View?.getView() = verticalLayout {
        style {
            width = 100.percent
            padding = 24.px
        }
        theme.bigTextView(this) {
            text = extendedRide?.let { "Change Pick up or Destination " } ?: "Request a new ride"
        }
        if(!isEdit){
            horizontalLayout {
                style {
                    width = matchParent
                    alignContent = Alignment.End
                }
                selectPickup.observe { pickup ->
                    selectDest.observe { dest ->
                        ridePromoCodeComponent.getActiveRidePromoCode(pickup?.lat, pickup?.lng, dest?.lat, dest?.lng)
                    }
                }
                mount(ridePromoCodeComponent)
            }
        }

        theme.label(this, "Pickup ", true)
        horizontalLayout {
            style {
                width = matchParent
            }
            selectPickup.observe {
                clearAllChildren()
                textView {
                    style {
                        width = matchParent
                        padding = 8.px
                        borderRadius = 4.px
                        border = "1px solid ${AppColors.borderColor}"
                        pointerCursor()
                        hover {
                            backgroundColor = AppColors.lightBackground
                        }
                    }
                    text = it?.address ?: it?.name ?: "Click to select".localized()
                    if (it == null) {
                        element.style.color = "${AppColors.textLight}"
                    }
                    onClick = {
                        showPickupDialog()
                    }
                }
            }
        }
        theme.label(this, "Destination".localized(), false)
        horizontalLayout {
            style {
                width = matchParent
            }
            selectDest.observe {
                clearAllChildren()
                textView {
                    style {
                        width = matchParent
                        padding = 8.px
                        borderRadius = 4.px
                        border = "1px solid ${AppColors.borderColor}"
                        pointerCursor()
                        hover {
                            backgroundColor = AppColors.lightBackground
                        }
                    }
                    text = it?.address ?: it?.name ?: "Click to select".localized()
                    if (it == null) {
                        element.style.color = "${AppColors.textLight}"
                    }
                    onClick = {
                        showDestinationDialog()
                    }
                }
            }
        }
        verticalLayout {
            style { width = matchParent }

            horizontalLayout {
                matchParentWidth()
                isVisible = !isEdit
                noteInput = theme.labeledTextInput(this, "Notes".localized(), false) {
                    placeholder = "Write notes for the driver".localized()
                }
            }


            horizontalLayout {
                matchParentWidth()
                theme.wrappedLabel(this, "Available car types".localized(), true).isVisible = !isEdit
                horizontalFiller()
                theme.menuButton(this) {
                    style {
                        margin = 10.px
                    }
                    text = "Clear destination".localized()
                    onClick = {
                        unselectDestination()
                    }
                }
            }

            extendedRide?.let { mount(RidePriceDisplayItem(it,"Current Price")) }
        }

        withLoadingAndError(viewModel.pickUpZoneIdUiState, onRetryClicked = {
            selectPickup.value?.let { viewModel.getPickupZoneId(it.lat, it.lng) }
        }, onLoaded = {
            val carType = extendedRide?.carType
            if (carType != null) {
                viewModel.carTypes = listOf(carType)
            } else {
                viewModel.getCarTypesList("")
            }
        })
        horizontalLayout {
            matchParentWidth()
            theme.wrappedLabel(this, "New Price".localized(), false).isVisible = isEdit
        }
        carTypeLayoutItem = verticalLayout {
            style { width = matchParent }
            withLoadingAndError(viewModel.carTypesUiState, onRetryClicked = {
                viewModel.getCarTypesList("")
            }, onLoaded = {
                clearAllChildren()
                selectCarTypeLayout()
            })
        }
        setFieldsForEditable()

        getNewPriceButton()
    }

    private fun View.getNewPriceButton() = horizontalLayout {

        matchParentWidth()
        selectPickup.observe { pickup ->
            selectDest.observe { dest ->
                viewModel.pricesUiState.value = null
                viewModel.extendedPrices = listOf()
                if (pickup != null){
                    saveUpdateLocations?.invoke(pickup, dest, null, null,null)
                }
                clearAllChildren()
                val isChanged
                = extendedRide?.ride?.pickupLat != pickup?.lat || extendedRide?.ride?.pickupLng != pickup?.lng || extendedRide?.ride?.destLat != dest?.lat || extendedRide?.ride?.destLng != dest?.lng

                console.log("Get New Price" + (isChanged && isEdit) + isChanged + isEdit )
                isVisible = isEdit
                horizontalFiller()
                var getPriceButton : Button? = null
                getPriceButton = theme.mainButton(this) {
                    style {
                        margin = 10.px
                    }
                    text = "Get New Price".localized()
                    onClick = {
                        //todo
                        viewModel.carTypesUiState.value = BasicUiState.Loaded
                        calculatePrice()
                    }
                    element.disabled = !isChanged
                }
                getPriceButton.withLoadingAndError(viewModel.pricesUiState, onLoaded = {
                val extendedPriceDataDto = viewModel.extendedPrices.firstOrNull()
                if (pickup != null){
                    saveUpdateLocations?.invoke(pickup, dest, extendedPriceDataDto?.token, extendedPriceDataDto?.priceData?.payablePrice, extendedPriceDataDto?.priceData?.currencyName   )
                }
                getPriceButton.element.disabled = true

                }, onRetryClicked = {
                    viewModel.carTypesUiState.value = BasicUiState.Loaded
                    calculatePrice()
                })
            }
        }
    }

    private fun showDestinationDialog() {
        val offSet = 0.001
        val center = selectPickup.value?.let {
            LocationPointDto(lat = it.lat + offSet , lng = it.lng + offSet, null, null, null, null)
        } ?: viewModel.latestRide?.let {  LocationPointDto(lat = it.pickupLat, lng = it.pickupLng, null, null, null, null)}
        pointSelectorDialog = PointSelectorDialog(
            passengerDto,
            selectPickup.value,
            selectDest.value,
            centerLocationPoint = center,
            onDismissed =  {
                onSelectionDialogDismissed()
            }
        )
        pointSelectorDialog?.pickLocationDialog(
            isPickup = false,
            onSaved = { _, point ->
                selectDest.value = point
                if(!isEdit){
                    calculatePrice()
                }
            },
            onBackSelected = {
                showPickupDialog()
            }
        )

    }

    private fun showPickupDialog(){
        val center = viewModel.latestRide?.let {  LocationPointDto(lat = it.pickupLat, lng = it.pickupLng, null, null, null, null)}
        pointSelectorDialog = PointSelectorDialog(
            passengerDto,
            selectPickup.value,
            selectDest.value,
            center,
            onDismissed = {
                onSelectionDialogDismissed()
            }
        )
            pointSelectorDialog?.pickLocationDialog(
            isPickup = true,
            onSaved = { isOpenRide, point ->
                selectPickup.value = point
                viewModel.getPickupZoneId(point.lat, point.lng)
                if (isOpenRide) {
                    selectDest.value = null
                } else {
                    if(!isEdit){
                        calculatePrice()
                    }
                    showDestinationDialog()
                }

            }
        )
    }

    fun onSelectionDialogDismissed(){
        pointSelectorDialog = null
    }
    private fun calculatePrice() {
        val selectedPickup = selectPickup.value ?: return
        val selectedDestination = selectDest.value ?: return

        viewModel.calculatePrices(selectedPickup, selectedDestination, isEdit, extendedRide?.ride)
    }

    private fun unselectDestination() {
        selectDest.value = null
        viewModel.extendedPrices = listOf()
        viewModel.pricesUiState.value = BasicUiState.Loaded
    }

    private fun rideRequestedDialog(extendedRide: ExtendedRideDto) {
        val rideId = extendedRide.ride.id
        if (rideId != null) {
            RideDetailsDialog(rideId, extendedRide, reloadRides = reloadRides, true) { onRideCreated.invoke() }.show()
        }
    }

    private fun View.selectCarTypeLayout() = verticalLayout {
        style {
            width = matchParent
            minWidth = 40.percent
        }
        verticalLayout {
            style {
                width = matchParent
            }
            viewModel.pricesUiState.observe {
                clearAllChildren()

                viewModel.carTypes.forEach { carType ->
                    val price =
                        viewModel.extendedPrices.firstOrNull { it.priceData.carTypeId == carType.id }

                    mount(
                        PriceDisplayItem(carType, price,
                            onSelected =
                            if(!isEdit){
                                {
                                showConfirmationDialogWithRemoteAction("Confirm Ride Request".localized(),
                                    "Are you sure you want to request this ride?.".localized(),
                                    "Confirm".localized(),
                                    null,
                                    action = {
                                        val pickup = selectPickup.value
                                        val dest = selectDest.value
                                        carType.id?.let { id ->
                                            if (pickup != null) {
                                                val note = noteInput?.text ?: ""
                                                viewModel.requestRide(pickup, dest, carType, price, note)
                                            }
                                        }
                                    },
                                    actionButtonStyle = null,
                                    uiState = viewModel.createRideUiState,
                                    onConfirmed = {})
                                }
                            } else
                                null
                        )
                    )
                }
            }
        }.withLoadingAndError(viewModel.createRideUiState, onRetryClicked = {

        }, onLoaded = {
            passengerDto.id?.let { reloadRides }
            viewModel.createdRide?.let { rideRequestedDialog(it) }
            clearForm()
        })
    }

    fun calculatePriceBefore(price: PriceDataDto): Double {
        val adjustment = price.profileGroupAdjustment ?: 0.0
        return (price.actualRidePrice - adjustment).roundToTwoDigits()


    }

    private fun clearForm() {
        selectPickup.value = null
        selectDest.value = null
        noteInput?.text = ""
        viewModel.carTypes = listOf()
        viewModel.extendedPrices = listOf()
        viewModel.carTypesUiState.value = null
        viewModel.pricesUiState.value = null
        viewModel.createRideUiState.value = null
        viewModel.createdRide = null
    }

    class Location(
        val lat: Double,
        val lng: Double,
        val name: String?,
        val address: String?
    )

    object Style {

        fun nearbyPoints(glyph: Element? = null) = json(
            "background" to AppColors.main.toCss(),
            "glyphColor" to Color.white.toCss(),
            "borderColor" to AppColors.main.toCss(),
            "glyph" to glyph
        )
        fun favoritePoints(glyph: Element?) = json(
            "background" to AppColors.activeColor.toCss(),
            "glyphColor" to Color.white.toCss(),
            "borderColor" to AppColors.activeColor.toCss(),
            "glyph" to glyph
        )
        fun historyPoints(glyph: Element?) = json(
            "background" to AppColors.black.toCss(),
            "glyphColor" to Color.white.toCss(),
            "borderColor" to AppColors.black.toCss(),
            "glyph" to glyph

        )
    }

}