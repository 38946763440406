package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.CompleteRideAdminEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class SelectedRideViewModel {
        val completeRideUiState = Observable<BasicUiState>()

    fun completeRide(rideId: Long){
        basicNetworkCall(completeRideUiState){
            val dto = CompleteRideAdminEndpoint.Request(rideId = rideId )
            CompleteRideAdminEndpoint.remoteProcess(dto)
        }
    }
}