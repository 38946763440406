package com.narbase.letsgo.web.views.callCenter

import com.narbase.almutkhassisa.web.views.googlemaps.*
import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.toHoursAndMinutes
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.callCenter.cancelride.CancelRideDialog
import com.narbase.letsgo.web.views.callCenter.updateride.UpdateRideDialog
import kotlin.js.json

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/06/03.
 */
class RideDetailsDialog(
    private val rideId: Long,
    private val extendedRideDto: ExtendedRideDto?,
    private val reloadRides: () -> Unit,
    private val isJustCreated: Boolean = false,
    private val onNextPassengerClicked: (() -> Unit),
) {
    private val popup by lazy {
        popUpDialog(
            onDismissed = {
                viewModel.clearRideDetailsTimer()
            }
        )
    }
    private val viewModel = RideDetailsViewModel(rideId, extendedRideDto)

    private val centerLat = 18.0735
    private val centerLng = -15.9582

    private val mapComponent by lazy {
        GoogleMap(
            640.px, json(
                "zoom" to 11,
                "center" to json("lat" to centerLat, "lng" to centerLng),
                "styles" to mapDarkStyle,
                "scrollwheel" to false,
                "mapId" to "Demo_MAP_ID"
            )
        )
    }

    fun show() {
        viewModel.setUpRideDetails()
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            viewModel.clearRideDetailsTimer()
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.unimportantButton(this) {
                        isVisible =
                            extendedRideDto != null && extendedRideDto.ride.status.enum() !in RideStatus.driverCannotTerminateStatuses()
                        text = "Cancel Ride".localized()
                        onClick = {
                            popup.dismissDialog()
                            CancelRideDialog(rideId = rideId).show()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        isVisible = isJustCreated
                        text = "Next Passenger".localized()
                        onClick = {
                            popup.dismissDialog()
                            onNextPassengerClicked.invoke()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        isVisible =
                            !isJustCreated && extendedRideDto != null
                        text = "Reorder".localized()
                        onClick = {
                            popup.dismissDialog()
                            viewModel.extendedRide?.let { ride ->
                                ReorderRideDialog(
                                    ride, reloadRides, onNextPassengerClicked = onNextPassengerClicked
                                ).show()
                            }
                        }
                    }


                }
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    justifyContent = JustifyContent.Center
                }
                horizontalLayout {
                    matchParentWidth()
                    theme.bigTextView(this) {
                        text = "Ride Details".localized()
                    }
                    horizontalFiller()
                    horizontalLayout {
                        style{
//                            width = matchParent
                            justifyContent = JustifyContent.End
                        }
                        withLoadingAndError(viewModel.getRideUiState, onLoaded = {
                            clearAllChildren()
                            isVisible = viewModel.extendedRide?.ride?.status?.enum() in RideStatus.locationsEditableStates()
                            theme.menuButton(this) {
                                text = "Change Pickup and Destination".localized()
                                onClick = {
                                    val rideId = viewModel.extendedRide?.ride?.id
                                    val extendedRide = viewModel.extendedRide
                                    if (rideId != null && extendedRide != null) {
                                        UpdateRideDialog(
                                            rideId, extendedRide, reloadRides = reloadRides, onLocationsChanged = {
                                                viewModel.getRideDetails()
                                            }
                                        ).show()
                                    }
                                }
                            }},
                            onRetryClicked = {
                                viewModel.getRideDetails()
                        } )
                    }
                }
                verticalFiller(4)
                horizontalLayout {
                    style {
                        width = matchParent
                        alignContent = Alignment.End
                    }
                    horizontalFiller()
                    horizontalLayout {
                        style {
                            width = wrapContent
                            alignContent = Alignment.Center
                            color = AppColors.activeColor
                        }
                        withLoadingAndError(viewModel.getRideUiState,
                            onRetryClicked = {
                                viewModel.getRideDetails()
                            },
                            onLoaded = {
                                clearAllChildren()
                                theme.simpleLabel(this) {
                                    text = "ride details up-to-date"
                                    style {
                                        color = AppColors.activeColor
                                    }
                                }
                            })
                    }
                }

                verticalLayout {
                    style { width = matchParent }
                    viewModel.getRideUiState.observe {
                        if (it == BasicUiState.Loaded) {
                            clearAllChildren()
                            viewModel.extendedRide?.let { ride -> rideDetailsLayout(ride) }
                        }
                    }
                }
            }
        }
    }

    private fun LinearLayout.rideDetailsLayout(item: ExtendedRideDto) {

        theme.text(this) {
            text = item.ride.bookingKey.toString()
        }
        verticalFiller(16)
        horizontalLayout {
            style {
                width = matchParent
                paddingEnd = 30.px
            }
            verticalLayout {
                style { weightOf(1) }
                theme.label(this, "PickUp: ".localized(), false)
                theme.mediumTextView(this) {
                    text = item.ride.pickupAddress
                }

                verticalLayout {
                    style { weightOf(1) }
                    theme.label(this, "Distance: ".localized(), false)
                    theme.mediumTextView(this) {
                        text = item.ride.distanceInKm?.let { "$it Km" } ?: ""
                    }
                }
            }
            horizontalFiller()
            verticalLayout {
                style { weightOf(1) }
                theme.label(this, "Destination: ".localized(), false)
                theme.mediumTextView(this) {
                    text = item.ride.destAddress ?: "Open Ride".localized()
                }
                verticalLayout {
                    style { weightOf(1) }
                    theme.label(this, "Duration: ".localized(), false)
                    theme.mediumTextView(this) {
                        text = item.ride.durationInMin?.let { "$it mins" } ?: ""
                    }
                }
            }

        }
        theme.label(this, "Time: ".localized(), false)

        horizontalLayout {
            theme.mediumTextView(this) {
                text = item.ride.time.toDate().toDateString()
            }
            horizontalFiller(20)
            theme.mediumTextView(this) {
                text = item.ride.time.toDate().toHoursAndMinutes()
            }
        }

        theme.label(this, "Status: ".localized(), false)
        theme.mediumTextView(this) {
            text = item.ride.status.enum().dtoName
        }
        verticalFiller(5)
        viewModel.extendedRide?.let { mount(RidePriceDisplayItem(it,"Price Details")) }

        if(item.ride.status.enum() == RideStatus.cancelled){
            theme.label(this, "Cancellation Reason: ".localized(), false)
            theme.mediumTextView(this) {
                text = item.ride.cancellationReason ?: "none"
            }
        }
        theme.label(this, "Note: ".localized(), false)
        theme.mediumTextView(this) {
            text = item.ride.notes ?: "none"
        }
        verticalLayout {
            theme.label(this, "Driver: ".localized(), false)
            theme.mediumTextView(this) {
                text = item.driver?.fullName ?: ""
            }
            theme.mediumTextView(this) {
                text = item.driver?.phone ?: ""
            }
            theme.mediumTextView(this) {
                text = item.driver?.licenseNumber ?: ""
            }
        }
        viewModel.extendedRide?.let { driverLocation(it) }



    }

    private fun LinearLayout.driverLocation(extendedRide: ExtendedRideDto) = view {
        style {
            width = 25.vw
            height = 25.vw
            position = "relative"
            alignSelf = Alignment.Center
        }
        isVisible = viewModel.extendedRide?.driver != null
        mount(mapComponent)


        val pin = imageView {
            element.src = "/public/img/driver_marker.png"
            style {
                width = 15.px
            }
        }
        mapComponent.addMarker(
            extendedRide.ride.pickupLat, extendedRide.ride.pickupLng, arrayOf(
                "title" to extendedRide.ride.pickupAddress,
                "content" to google.Maps.PinElement(RequestRideComponent.Style.nearbyPoints(null)).element
            )
        )
        val destLat = extendedRide.ride.destLat
        val destLng = extendedRide.ride.destLng
        if (destLat != null && destLng != null) {

            mapComponent.addMarker(
                destLat, destLng, arrayOf(
                    "title" to (extendedRide.ride.destAddress ?: "Destination".localized()),
                    "content" to google.Maps.PinElement(RequestRideComponent.Style.favoritePoints(null)).element
                )
            )
        }
        viewModel.getRideUiState.observe {
            extendedRide.driver?.let {
                mapComponent.setZoom(13f)

                val latCenter = listOf(extendedRide.ride.pickupLat, it.lat).average()
                val lngCenter = listOf(extendedRide.ride.pickupLng, it.lng).average()
                mapComponent.addMarker(
                    it.lat, it.lng, arrayOf(
                        "title" to it.fullName,
                        "content" to pin.element
                    )
                )
                mapComponent.moveToLocation(latCenter, lngCenter)
            }
        }
    }

}