package com.narbase.letsgo.web.views.driverTargets.targetConfig

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.web.dto.config.ConfigDto
import com.narbase.letsgo.web.dto.config.TargetConfigDto
import com.narbase.letsgo.web.dto.drivertargets.ExtendedTargetDto
import com.narbase.letsgo.web.dto.drivertargets.TargetDto
import com.narbase.letsgo.web.models.TargetsStatus
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.drivertargets.GetTargetsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.drivertargets.UpsertTargetEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.GetConfigEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertConfigEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class TargetConfigViewModel {

    val uiState = Observable<BasicUiState>()
    val getConfigUiState = Observable<BasicUiState>()
    val updateUiState = Observable<BasicUiState>()

    var config : ConfigDto? = null
    var targetConfig : TargetConfigDto? = null


    var pageNo = 0
    var pageSize = 20
    var total = 0
    fun getConfig(){
        basicNetworkCall(getConfigUiState) {
            val dto = GetConfigEndpoint.Request()
            val response = GetConfigEndpoint.remoteProcess(dto).data
            config = response.data
            targetConfig = response.data?.targetConfig
            console.log("Target Config newTargetMessage: " +  targetConfig?.newTargetMessage)
            console.log(" Config : " +  config?.callCenterConfig?.phoneNumber)
        }
    }

    fun upsertConfig(targetConfig: TargetConfigDto) {
        val configDto = config ?: return
        val updateConfig = ConfigDto(
            callCenterConfig = configDto.callCenterConfig,
            orderingIncentiveConfig = configDto.orderingIncentiveConfig,
            timeoutIncentiveConfig = configDto.timeoutIncentiveConfig,
            ipLockingConfig = configDto.ipLockingConfig,
            targetConfig = targetConfig)
        basicNetworkCall(updateUiState) {
            val dto = UpsertConfigEndpoint.Request(updateConfig)
            val response = UpsertConfigEndpoint.remoteProcess(dto).data
        }

    }
}