package com.narbase.letsgo.web.views.callCenter.selectedpassenger

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.dto
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.models.PhoneNumberType
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.ToggleSwitch
import com.narbase.letsgo.web.utils.views.materialIcon
import com.narbase.letsgo.web.utils.views.pointerCursor
import com.narbase.letsgo.web.views.user.profile.EditablePhoneNumberView

class EditPassengerPhoneNumberComponent(
    val phoneNumber: PassengerPhoneNumberDto?,
    private val passengerId : Long,
    val isInvalid: (error: String) -> Unit,
    val onRemove: (EditPassengerPhoneNumberComponent) -> Unit
) : Component(
) {
    private val phoneEditableView = EditablePhoneNumberView()

    private val isActiveSelected: Observable<Boolean> = Observable()
    private val isWhatsAppSelected: Observable<Boolean> = Observable()



    override fun View?.getView() = horizontalLayout {
        style {
            width = matchParent
            padding = 10.px
            margin = 10.px
            alignItems = Alignment.Center
            border = "1px solid ${AppColors.borderColor}"
            borderRadius = 8.px

        }

        mount(phoneEditableView)
        phoneEditableView.phoneNumber = phoneNumber?.phone ?: ""
        phoneEditableView.code = phoneNumber?.callingCode ?: ""
        phoneEditableView.enableEdit()
        horizontalFiller()
        isSelected(phoneNumber?.isActive ?: true, isActiveSelected, "Active".localized())
        horizontalFiller(10)
        val isWhatsApp = phoneNumber?.type?.enum() == PhoneNumberType.WHATSAPP
        isSelected(isWhatsApp, isWhatsAppSelected, "Whatsapp".localized())

        verticalLayout {
            style {
                height = matchParent
                width = 18.px

            }
            materialIcon("delete"){
                style {
                    pointerCursor()
                }
                onClick = {
                    onRemove.invoke(this@EditPassengerPhoneNumberComponent)
                }
            }
            verticalFiller()

        }

    }


    fun getInfo(): PassengerPhoneNumberDto? {
        val type = if (isWhatsAppSelected.value == true) PhoneNumberType.WHATSAPP else PhoneNumberType.CALLING
        val isActive = isActiveSelected.value == true
        if (phoneEditableView.phoneNumber.isBlank()) {
            isInvalid.invoke("phone number can't be null".localized())
            return null
        }

        if (phoneEditableView.code.isBlank()) {
            isInvalid.invoke("calling code can't be null".localized())
            return null
        }

        return PassengerPhoneNumberDto(
            id = phoneNumber?.id,
            passengerId = passengerId,
            callingCode = phoneEditableView.code,
            phone = phoneEditableView.phoneNumber,
            type = type.dto(),
            isActive = isActive,
            createdOn = null
        )
    }

    private fun LinearLayout.isSelected(
        isEnabled: Boolean,
        isEnabledSelected: Observable<Boolean>,
        label: String
    ): ToggleSwitch {
        val switch = ToggleSwitch()
        verticalLayout {
            switch.resetIsSelected()
            switch.isSelected.value = isEnabled
            switch.isSelected.observe {
                if (it != null) {
                    isEnabledSelected.value = it
                }
            }
            theme.label(this, label, false)
            mount(switch)
        }
        return switch
    }
}