package com.narbase.letsgo.web.routing.domain.admin.passengers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerDto

object GetPassengerByPhoneEndpoint : EndPoint<GetPassengerByPhoneEndpoint.Request, GetPassengerByPhoneEndpoint.Response>() {
    class Request(
        val callingCode: String?,
        val phone: String?,
    )

    class Response(
        val extendedPassengerDto: ExtendedPassengerDto?,
    )
}