package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.views.matchParentDimensions
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.withLoadingAndError

class RidesManagementComponent : Component() {
    private val viewModel = RidesManagementViewModel()
    var selectedPassengerLayout: LinearLayout? = null
    private fun getSelectedRide(rideId: Long) {
        viewModel.getRide(rideId)
    }

    override fun View?.getView() = view {
        id = "ridesManagementRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                }
                viewModel.selectedRide.let { extendedRide ->
                    val rideId = extendedRide?.ride?.id
                    if (extendedRide == null) {
                        noRideSelectedView()
                    }
                    withLoadingAndError(viewModel.getRideUiState, onRetryClicked = {
                        if (rideId != null) {
                            viewModel.getRide(rideId)
                        }
                    },

                        onLoaded = {
                            clearAllChildren()
                            selectedPassengerLayout = verticalLayout {
                                style {
                                    width = matchParent
                                    height = wrapContent
                                }
                                val selectedRide = viewModel.selectedRide
                                if (selectedRide != null) {
                                    selectedRideLayout(selectedRide)
                                }
                            }
                        })
                }
            }
        }
    }

    private fun View.noRideSelectedView() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
            }
            titleView()
            horizontalFiller()
            textView {
                text = "No Ride Selected".localized()
                style {
                    color = AppColors.textLight
                }
            }
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Select Ride"
                onClick = { selectRideDialog() }
            }
        }

    }

    private fun LinearLayout.titleView() {
        textView {
            text = "Rides Management".localized()
            style {
                width = wrapContent
                fontSize = 20.px
                fontWeight = "bold"
            }
        }
    }


    private fun View.selectedRideLayout(extendedRideDto: ExtendedRideDto) {
        selectedRideHeader()
        val rideId = extendedRideDto.ride.id
        selectedPassengerLayout = verticalLayout {
            matchParentWidth()

            if (rideId != null) {
                mount(
                    SelectedRideComponent(
                        extendedRideDto,
                        onRideChanged = {
                            viewModel.getRide(rideId)
                        }
                    )
                )
            }
        }

    }

    private fun View.selectedRideHeader() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
                borderBottom = "1px solid ${AppColors.separatorLight}"
            }

            titleView()

            horizontalFiller(16)
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Clear Ride".localized()
                onClick = {
                    viewModel.clearSelectedRide()
                    selectedPassengerLayout?.clearAllChildren()
                    selectedPassengerLayout?.noRideSelectedView()
                    selectRideDialog()
                }
            }

        }
    }

    private fun selectRideDialog() {
        RideSelectorDialog {
            getSelectedRide(it)
        }.show()
    }

}