package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.ridepromocode.RidePromoCodeDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.*

class RidePromoCodeDisplayComponent(
    private val passengerId: Long,
    private val onActivePromoCodeChanged: (() -> Unit)? = null
) : Component(
) {
    val viewModel = RidePromoCodeViewModel(passengerId)

    var pickupLat: Double? = null
    var pickupLng: Double? = null
    var destLat: Double? = null
    var destLng: Double? = null


    fun getActiveRidePromoCode(pickupLat: Double?, pickupLng: Double?, destLat: Double?, destLng: Double?) {
        viewModel.getActivePromoCode(pickupLat, pickupLng, destLat, destLng)
        setCoordinates(pickupLat, pickupLng, destLat, destLng)

    }

    fun setCoordinates(pickuplat: Double?, pickuplng: Double?, destlat: Double?, destlng: Double?) {
        pickupLat = pickuplat
        pickupLng = pickuplng
        destLat = destlat
        destLng = destlng

    }

    override fun View?.getView(): View {
        return verticalLayout {
            style {
                width = matchParent

            }
            withLoadingAndError(viewModel.getActivePromoCodeUiState, onRetryClicked = {
                viewModel.getActivePromoCode(pickupLat, pickupLng, destLat, destLng)
            }, onLoaded = {
                clearAllChildren()
                val activePromoCode = viewModel.activePromoCode
                if (activePromoCode != null) {
                    promoCodeDetailsLayout(activePromoCode)
                } else {
                    horizontalLayout {
                        style {
                            matchParentWidth()
                            justifyContent = JustifyContent.Start
                        }
                        verticalLayout {
                            style {
                                border = "1px solid ${AppColors.borderColor}"
                                borderRadius = 4.px
                                backgroundColor = AppColors.extraLightBackground
                                padding = 5.px
                                flexShrink = "1"
                                whiteSpace = "nowrap"
                                overflow = "hidden"
                            }
                            theme.text(this) {
                                style {
                                    textOverflow = "ellipsis"
                                }
                                text = "No Active Promo Code".localized()
                            }
                        }
                        horizontalFiller(4)
                        horizontalLayout {
                            style {
                                flexShrink = "1"
                                whiteSpace = "nowrap"
                                overflow = "hidden"
                                borderRadius = 4.px
                                padding = 4.px
                                backgroundColor = AppColors.mainColor
                                pointerCursor()
                                hover {
                                    backgroundColor = AppColors.mainDark
                                }
                            }
                            onClick = {
                                RidePromoCodeRedeemDialog(
                                    viewModel,
                                    onPromoCodeRedeemed = {
                                        viewModel.getActivePromoCode(pickupLat, pickupLng, destLat, destLng)
                                        onActivePromoCodeChanged?.invoke()
                                    }
                                ).show()
                            }
                            materialIcon("redeem") {
                                style {
                                    color = AppColors.white
                                }
                                size = MaterialIcon.md18
                            }
                            horizontalFiller(4)
                            textView {
                                style {
                                    fontSize = 14.px
                                    color = AppColors.white
                                }
                                text = "Redeem".localized()
                            }
                        }
                    }

                }

            })

        }
    }

    private fun LinearLayout.promoCodeDetailsLayout(promo: RidePromoCodeDto) = horizontalLayout {
        style {
            width = matchParent
            flexShrink = "1"
            whiteSpace = "nowrap"
            overflow = "hidden"
        }

        val display = if (promo.isPercentage) "%" else promo.currencyName
        horizontalFiller(10)
        theme.wrappedLabel(this, "Ride Promo:".localized(), false)
        horizontalLayout {
            style { width = weightOf(1)
            maxWidth = 24.px}

        }
        view {
            style {
                width = wrapContent
                backgroundColor = AppColors.mainColor
                borderRadius = 5.px
                padding = 5.px
            }

            theme.mediumTextView(this) {
                style {
                    color = Color.white
                }
                text = promo.amount.toString() + display
            }
        }
        horizontalFiller(15)
        materialIcon("close") {
            style {
                padding = 5.px
                color = AppColors.white
                backgroundColor = Color(0, 0, 0, 0.2)
                pointerCursor()
                borderRadius = 8.px
                hover {
                    backgroundColor = Color(0, 0, 0, 0.6)
                }
            }
            size = MaterialIcon.md18
            onClick = {
                viewModel.deactivate()
                onActivePromoCodeChanged?.invoke()
            }
        }

    }
}