package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.price.ExtendedPriceDataDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.price.GetRidePricesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.ReorderRideAdminEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import kotlin.js.Date

class ReorderRideViewModel(
    var extendedRide: ExtendedRideDto
) {
    val pricesUiState = Observable<BasicUiState>()
    var newRideExtendedPrice: ExtendedPriceDataDto? = null
    val reorderRideUiState = Observable<BasicUiState>()
    var newRideId : Long? = null

    fun reorderRide(){
        val passengerId = extendedRide.passenger.id ?: return
        val rideId = extendedRide.ride.id ?: return
        val ridePhoneNumber = extendedRide.ridePhoneNumber ?: return
        val priceToken = newRideExtendedPrice?.token
        basicNetworkCall(reorderRideUiState) {
            val dto = ReorderRideAdminEndpoint.Request(passengerId = passengerId, rideId = rideId, priceToken = priceToken, PhoneNumberDto(ridePhoneNumber.callingCode, ridePhoneNumber.phone) )
            val response = ReorderRideAdminEndpoint.remoteProcess(dto)
            newRideId = response.data.requestId
        }
    }

    fun calculatePrices() {
        val passengerId = extendedRide.passenger.id ?: return
        val carTypeId = extendedRide.ride.carTypeId ?: return
        val destLat = extendedRide.ride.destLat ?: return
        val destLng = extendedRide.ride.destLng ?: return
        basicNetworkCall(pricesUiState) {
            val response = GetRidePricesEndpoint.remoteProcess(
                GetRidePricesEndpoint.Request(
                    passengerId = passengerId,
                    time = null,
                    pickupLat = extendedRide.ride.pickupLat,
                    pickupLng = extendedRide.ride.pickupLng,
                    destLat = destLat,
                    destLng = destLng,
                    carTypeIds = arrayOf(carTypeId)
                )
            )
            newRideExtendedPrice = response.data.prices.firstOrNull { it.priceData.carTypeId == carTypeId }
        }
    }
}