package com.narbase.letsgo.web.views.callCenter

import SelectedPassengerComponent
import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.views.matchParentDimensions

class CallCenterComponent : Component() {
    private val selectedPassengerDto: Observable<ExtendedPassengerDto?> = Observable<ExtendedPassengerDto?>()
    private var searchedPhoneNumber: PhoneNumberDto? = null
    private fun setSelectedPassenger(extendedPassenger: ExtendedPassengerDto?, phoneNumber: PhoneNumberDto) {
        searchedPhoneNumber = phoneNumber
        selectedPassengerDto.value = extendedPassenger
    }

    override fun View?.getView() = view {
        id = "callCenterRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                }
                selectedPassengerDto.observe { extendedPassengerDto ->
                    clearAllChildren()
                    if (extendedPassengerDto == null) {
                        noPassengerSelectedView()
                    } else {
                        val phoneNumber = searchedPhoneNumber
                        if(phoneNumber != null) {
                            selectedPassengerLayout(extendedPassengerDto, phoneNumber)
                        }
                    }
                }
            }
        }
    }

    private fun View.noPassengerSelectedView() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
            }
            titleView()
            horizontalFiller()
            textView {
                text = "No Passenger Selected".localized()
                style {
                    color = AppColors.textLight
                }
            }
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Select Passenger"
                onClick = { selectPassengerDialog() }
            }
        }

    }

    private fun LinearLayout.titleView() {
        textView {
            text = "Call Center".localized()
            style {
                width = wrapContent
                fontSize = 20.px
                fontWeight = "bold"
            }
        }
    }


    private fun View.selectedPassengerLayout(extendedPassengerDto: ExtendedPassengerDto, phoneNumber: PhoneNumberDto) {
        selectedPassengerHeader()
        mount(SelectedPassengerComponent(extendedPassengerDto, onNextPassengerClicked = {
            setSelectedPassenger(null, PhoneNumberDto("",""))
            selectPassengerDialog()

        },
            searchedPhoneNumber = phoneNumber
        ))
    }

    private fun View.selectedPassengerHeader() {
        horizontalLayout {
            style {
                width = matchParent
                alignItems = Alignment.Center
                padding = 24.px
                borderBottom = "1px solid ${AppColors.separatorLight}"
            }

            titleView()

            horizontalFiller(16)
            horizontalFiller()
            theme.mainMenuButton(this) {
                text = "Clear passenger".localized()
                onClick = {
                    setSelectedPassenger(null, PhoneNumberDto("",""))
                    selectPassengerDialog()
                }
            }

        }
    }

    private fun selectPassengerDialog() {
        PassengerSelectorDialog(::setSelectedPassenger).show()
    }


}