package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.dto.cartypes.CarTypeDto
import com.narbase.letsgo.web.dto.favoritepoints.FavoritePointDto
import com.narbase.letsgo.web.dto.favoritepoints.getFullAddress
import com.narbase.letsgo.web.dto.locationpoint.LocationPointDto
import com.narbase.letsgo.web.dto.passengerlocation.PassengerLocationDto
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.dto.pointsOfIntrest.PointOfInterestDto
import com.narbase.letsgo.web.dto.pointsOfIntrest.getFullAddress
import com.narbase.letsgo.web.dto.price.ExtendedPriceDataDto
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.dto.rides.NewAdminRideRequestDto
import com.narbase.letsgo.web.dto.rides.RideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.cartypes.GetCarTypesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.CreateFavoritePointEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.DeleteFavoritePointEndpoint
import com.narbase.letsgo.web.routing.domain.admin.favoritepoints.GetPassengerFavoritePointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.geocode.GetGeocodeEndpoint
import com.narbase.letsgo.web.routing.domain.admin.locationpoints.GetHistoryPointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.locationpoints.GetPassengerLocationPointsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengerlocation.GetPassengerLocationEndpoint
import com.narbase.letsgo.web.routing.domain.admin.pointofinterest.GetAreaPointsOfInterestEndpoint
import com.narbase.letsgo.web.routing.domain.admin.price.GetRidePricesEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.GetLatestPassengerRideEndpoint
import com.narbase.letsgo.web.routing.domain.admin.rides.RequestRideAdminEndpoint
import com.narbase.letsgo.web.routing.domain.admin.zones.GetPointZoneIdEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import kotlin.js.Date

class RequestRideViewModel(
    val passengerDto: PassengerDto
) {

    private var searchTerm = ""
    val favotiesUiState = Observable<BasicUiState>()
    var favoritePoints: List<FavoritePointDto> = emptyList()
    val pointsUiState = Observable<BasicUiState>()
    var pointsOfInterest: List<PointOfInterestDto> = emptyList()
    val carTypesUiState = Observable<BasicUiState>()
    var carTypes: List<CarTypeDto> = listOf()
    val pricesUiState = Observable<BasicUiState>()
    var extendedPrices: List<ExtendedPriceDataDto> = listOf()
    val pickUpZoneIdUiState = Observable<BasicUiState>()
    private var pickUpZoneId: Int? = null
    val locationPointsUiState = Observable<BasicUiState>()
    var locationPoints: List<LocationPointDto> = listOf()
    val createFavotriteUiState = Observable<BasicUiState>()
    val deleteFavotriteUiState = Observable<BasicUiState>()
    val createRideUiState = Observable<BasicUiState>()
    var createdRide: ExtendedRideDto? = null
    val getAddressUiState = Observable<BasicUiState>()
    var passengerLocationDto: PassengerLocationDto? = null
    val passengerLocationUiState = Observable<BasicUiState>()

    val locationPointsPageSize = 30
    var locationPointSearchTerm = ""

    var historyPoints: List<LocationPointDto> = emptyList()
    val historyPointsUiState = Observable<BasicUiState>()
    var latestRide: RideDto? = null
    val latestRideUiState = Observable<BasicUiState>()


    fun getFavoritePoints() {
        val passengerId = passengerDto.id ?: return
        basicNetworkCall(favotiesUiState) {
            val dto = GetPassengerFavoritePointsEndpoint.Request(passengerId, 0, 100, searchTerm)
            val response = GetPassengerFavoritePointsEndpoint.remoteProcess(dto).data
            favoritePoints = response.list.toList()
        }
    }

    fun searchFor(term: String) {
        searchTerm = term
        favotiesUiState.value = BasicUiState.Loaded
        getFavoritePoints()
    }

    fun getAreaPointsOfInterest(startLat: Double, startLng: Double, endLat: Double, endLng: Double, term: String) {
        basicNetworkCall(pointsUiState) {
            val dto = GetAreaPointsOfInterestEndpoint.Request(0, 100, term, startLat, startLng, endLat, endLng)
            val response = GetAreaPointsOfInterestEndpoint.remoteProcess(dto).data
            pointsOfInterest = response.list.toList()
        }
    }

    fun getRecentPassengerLocation(){
        val passengerId = passengerDto.id ?: return
        basicNetworkCall(passengerLocationUiState) {
            val dto = GetPassengerLocationEndpoint.Request(passengerId)
            val response = GetPassengerLocationEndpoint.remoteProcess(dto).data
            passengerLocationDto = response.passengerLocation
        }
    }

    fun getCarTypesList(carTypeSearchTerm: String) {
        basicNetworkCall(carTypesUiState) {
            val response = GetCarTypesEndpoint.remoteProcess(
                GetCarTypesEndpoint.Request(
                    0,
                    100,
                    carTypeSearchTerm,
                    filter = GetCarTypesEndpoint.Filter(zoneId = pickUpZoneId)
                )
            )
            carTypes = response.data.list.toList()
        }
    }

    fun getPickupZoneId(lat: Double, lng: Double) {
        basicNetworkCall(pickUpZoneIdUiState) {
            val response = GetPointZoneIdEndpoint.remoteProcess(GetPointZoneIdEndpoint.Request(lat = lat, lng = lng))
            pickUpZoneId = response.data.zoneId
        }

    }

    fun calculatePrices(pickup: LocationPointDto, dest: LocationPointDto, isEdit: Boolean, ride: RideDto?) {
        val passengerId = passengerDto.id ?: return
        val carTypeIds = if(isEdit){
            carTypes.map {
                val id = it.id ?: return
                id
            }.toTypedArray()
        } else null
        val rideId = ride?.id
        val existingRidePromoInfo = if(isEdit && ride != null && rideId != null){
            GetRidePricesEndpoint.ExistingRidePromoInfo(
                rideId = rideId,
                ridePromoId = ride.ridePromoId,
                ridePromoDiscount = ride.ridePromoDiscount
            )
        } else null
        basicNetworkCall(pricesUiState) {
            val response = GetRidePricesEndpoint.remoteProcess(
                GetRidePricesEndpoint.Request(
                    passengerId = passengerId,
                    time = null,
                    pickupLat = pickup.lat,
                    pickupLng = pickup.lng,
                    destLat = dest.lat,
                    destLng = dest.lng,
                    carTypeIds = carTypeIds,
                    existingRidePromoInfo = existingRidePromoInfo
                )
            )
            extendedPrices = response.data.prices.toList()
            extendedPrices.forEach {
                console.log(it.priceData.carTypeId.toString() + " " + it.priceData.actualRidePrice + it.priceData.currencyName)
            }

        }

    }

    fun getLocationPoints(pageNo: Int, term: String?, lat: Double?, lng: Double? ) {
        val passengerId = passengerDto.id ?: return
        if (term != null) {
            locationPointSearchTerm = term
        }
        basicNetworkCall(locationPointsUiState) {
            val response = GetPassengerLocationPointsEndpoint.remoteProcess(
                GetPassengerLocationPointsEndpoint.Request(
                    passengerId,
                    pageNo,
                    locationPointsPageSize,
                    locationPointSearchTerm,
                    3,
                    lat,
                    lng

                )
            )
            locationPoints = response.data.list.toList()
        }

    }

    fun addFavoritePoint(location: LocationPointDto): FavoritePointDto?{
        val passengerId = passengerDto.id ?: return null
        val fullAddress = location.rideAddress()
        val favoritePointDto = FavoritePointDto(
            id = null,
            passengerId = passengerId,
            lat = location.lat,
            lng = location.lng,
            fullAddress = fullAddress,
            name = location.name ?: "No Name"
        )
        var favoritePointId: Long? = null
        basicNetworkCall(createFavotriteUiState) {
            val response = CreateFavoritePointEndpoint.remoteProcess(CreateFavoritePointEndpoint.Request(favoritePointDto = favoritePointDto))
            favoritePointId =  response.data.favoritePointId
        }

        return FavoritePointDto(
            id =  favoritePointId,
            passengerId = favoritePointDto.passengerId,
            lat = favoritePointDto.lat,
            lng = favoritePointDto.lng,
            fullAddress = favoritePointDto.fullAddress,
            name = favoritePointDto.name
        )

    }

    fun deleteFavoritePoint(id: Long){
        basicNetworkCall(deleteFavotriteUiState) {
            val response = DeleteFavoritePointEndpoint.remoteProcess(DeleteFavoritePointEndpoint.Request(favoritePointId = id))
        }
    }

    private fun LocationPointDto.rideAddress(): String {
        return pointOfInterestDto?.getFullAddress()
            ?: favoritePointDto?.getFullAddress()
            ?: address ?: "No address"
    }

    fun requestRide(
        pickup: LocationPointDto,
        dest: LocationPointDto?,
        cartype: CarTypeDto,
        extendedPriceDataDto: ExtendedPriceDataDto?,
        note: String
    ) {
        val passengerId = passengerDto.id ?: return

        val pickupAddress = pickup.rideAddress()
        val destAddress = dest?.rideAddress()

        console.log("Passenger ID: " + passengerId)

        basicNetworkCall(createRideUiState) {
            val response = RequestRideAdminEndpoint.remoteProcess(
                RequestRideAdminEndpoint.Request(
                    NewAdminRideRequestDto(
                        passengerId = passengerId,
                        pickupLat = pickup.lat,
                        pickupLng = pickup.lng,
                        pickupAddress = pickupAddress,
                        destLat = dest?.lat,
                        destLng = dest?.lng,
                        destAddress = destAddress,
                        carTypeId = cartype.id!!,
                        carType = cartype.nameEn,
                        time = null,
                        notes = note,
                        priceAfterDiscount = extendedPriceDataDto?.priceData?.payablePrice,
                        priceBeforeDiscount = extendedPriceDataDto?.priceData?.actualRidePrice,
                        fcmToken = null,
                        senderKey = null,
                        currencyId = extendedPriceDataDto?.priceData?.currencyId,
                        priceToken = extendedPriceDataDto?.token,
                    )
                )
            )

            createdRide = response.data.extendedRideDto
        }
    }

    fun getGeocodeAddress(lat: Double, lng: Double, onAddressFound: (String) -> Unit) {
        basicNetworkCall(getAddressUiState) {
            val dto = GetGeocodeEndpoint.Request(lat, lng, onIdle = false)
            val response = GetGeocodeEndpoint.remoteProcess(dto).data
            onAddressFound.invoke(response.address)
        }
    }

    fun getHistoryPoints() {
        val passengerId = passengerDto.id ?: return
        basicNetworkCall(historyPointsUiState) {
            val dto = GetHistoryPointsEndpoint.Request(passengerId, 0, 10, "" )
            val response = GetHistoryPointsEndpoint.remoteProcess(dto).data

            historyPoints = response.list.distinctBy { Pair(it.lng, it.lat) }
                .toList()
        }
    }

    fun getLatestRide(){
        val passengerId = passengerDto.id ?: return
        basicNetworkCall(latestRideUiState) {
            val dto = GetLatestPassengerRideEndpoint.Request(passengerId )
            val response = GetLatestPassengerRideEndpoint.remoteProcess(dto).data
            latestRide = response.ride
        }
    }
}