package com.narbase.letsgo.web.routing.domain.admin.passengers.phonenumbers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto

object GetPassengerPhoneNumbersEndpoint : EndPoint<GetPassengerPhoneNumbersEndpoint.Request, GetPassengerPhoneNumbersEndpoint.Response>() {
    class Request(
        val passengerId: Long,
        val pageNo: Int,
        val pageSize: Int,
    )

    class Response(
        val phoneNumbers: Array<PassengerPhoneNumberDto>
    )
}