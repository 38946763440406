package com.narbase.letsgo.web.views.callCenter.selectedpassenger

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.dto.passengers.PhoneNumberAvailabilityDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.passengers.UpsertPassengerPhoneNumbersEndpoint
import com.narbase.letsgo.web.routing.domain.admin.passengers.phonenumbers.DeletePassengerPhoneNumberEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class EditPhoneNumbersViewModel {

    val saveRideUiState = Observable<BasicUiState>()
    var phoneNumberAvailability: PhoneNumberAvailabilityDto? = null

    val deletePhoneNumberUiState = Observable<BasicUiState>()

    fun savePhoneNumbers(phoneNumbers: List<PassengerPhoneNumberDto>) {
        basicNetworkCall(saveRideUiState) {
            val dto = UpsertPassengerPhoneNumbersEndpoint.Request(phoneNumbers.toTypedArray())
            phoneNumberAvailability =
                UpsertPassengerPhoneNumbersEndpoint.remoteProcess(dto).data.phoneNumberAvailabilityDto
        }
    }

    fun deletePhoneNumber(phoneNumberId: Long) {
        basicNetworkCall(deletePhoneNumberUiState) {
            val dto = DeletePassengerPhoneNumberEndpoint.Request(phoneNumberId)
            DeletePassengerPhoneNumberEndpoint.remoteProcess(dto)
        }
    }
}