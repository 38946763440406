package com.narbase.letsgo.web.views.callCenter

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.models.PhoneNumberType
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.formatDateAndTime
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.user.profile.EditablePhoneNumberView

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2024/06/02.
 */
class PassengerSelectorDialog(
    private val onPassengerSelected: (
        extendedPassenger: ExtendedPassengerDto,
        searchedPhoneNumber: PhoneNumberDto) -> Unit
) {
    private var passengerRegistrationForm: RegisterPassengerForm? = null
    private var registerPassengerButtonLayout: LinearLayout? = null
    private var searchByPhoneLayout: LinearLayout? = null
    private val popup by lazy { popUpDialog() }
    private val viewModel = PassengerSelectorViewModel()
    private val phoneEditableView = EditablePhoneNumberView()

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = { popup.dismissDialog() }
                    }
                    registerPassengerButtonLayout = horizontalLayout {
                        horizontalFiller(8)
                        theme.mainButton(this) {
                            text = "Register passenger".localized()
                            onClick = {
                                onRegisterPassengerClicked()
                            }
                            withLoadingAndError(viewModel.registerPatientUiState,
                            onRetryClicked = { onRegisterPassengerClicked() },
                            onLoaded = {
                                val callingCode = phoneEditableView.code.removePrefix("+")
                                val phone = phoneEditableView.phoneNumber
                                popup.dismissDialog()

                                viewModel.newRegisteredPassenger?.let{ passenger -> onPassengerSelected( passenger, PhoneNumberDto(callingCode, phone))}
                            })
                        }
                    }

                }
            }

            theme.bigTextView(this) {
                text = "Find a passenger".localized()
            }
            verticalFiller(4)
            theme.text(this) {
                text = "Search passengers using phone number".localized()
            }
            verticalFiller(16)
            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    backgroundColor = Color.white
                    alignItems = Alignment.Center
                }
                searchByPhoneLayout = verticalLayout {

                    theme.label(this, "Phone number".localized(), true)
                    form {
                        element.onsubmit = {
                            it.preventDefault()
                        }
                        horizontalLayout {
                            style {
                                width = matchParent
                                justifyContent = JustifyContent.Center
                            }

                            mount(phoneEditableView)
                            phoneEditableView.enableEdit()

                            horizontalFiller(10)

                            theme.menuButton(this) {
                                element.type = "submit"
                                horizontalLayout {
                                    style {
                                        alignSelf = Alignment.Center
                                        padding = 4.px
                                    }
                                    imageView {
                                        style {
                                            alignSelf = Alignment.Center
                                            width = 12.px
                                            height = 12.px
                                        }
                                        element.src = "/public/img/search.png"
                                    }
                                    horizontalFiller(8)
                                    textView {
                                        text = "Search passengers".localized()
                                    }
                                }
                                onClick = {
                                   search()
                                }
                            }
                        }
                    }
                }
                verticalFiller(10)

                verticalLayout {
                    matchParentWidth()
                    withLoadingAndError(
                        viewModel.uiState,
                        onRetryClicked = viewModel::getPassenger,
                        onLoaded = { onListLoaded() }
                    )
                }
            }

        }
        onDialogCreated()
    }


    fun search() {
        if (phoneEditableView.phoneNumber.trim().isBlank() || phoneEditableView.phoneNumber.trim().length < 5 ) {
            SnackBar.showText("Phone number is invalid".localized())
            return
        }
        if (phoneEditableView.code.trim().isBlank()) {
            SnackBar.showText("Calling code is invalid".localized())
            return
        }
        viewModel.searchFor(phoneEditableView.code.removePrefix("+"), phoneEditableView.phoneNumber)
    }
    private fun onDialogCreated() {
        phoneEditableView.focusOnPhoneNumberInput()
        registerPassengerButtonLayout?.isVisible = false
    }

    private fun View.onListLoaded() {
        clearAllChildren()
        val foundItem = viewModel.data
        if (foundItem == null) {
            searchByPhoneLayout?.isVisible = false
            textView {
                style {
                    width = matchParent
                    paddingHorizontal = 8.px
                    color = AppColors.textLight
                    textAlign = TextAlign.Center
                }
                text = "No passenger found".localized()
            }
            verticalFiller(8)
            passengerRegistrationForm = mount(RegisterPassengerForm("${phoneEditableView.code}${phoneEditableView.phoneNumber}"))
            registerPassengerButtonLayout?.isVisible = true

        } else {
            searchByPhoneLayout?.isVisible = true
            populateFoundPassengerView(foundItem)
        }
    }

    private fun View.populateFoundPassengerView(item: ExtendedPassengerDto) {
        verticalLayout {
            style {
                width = matchParent
                pointerCursor()
                padding = 8.px
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                hover { backgroundColor = AppColors.lightBackground }
            }
            theme.bigTextView(this) {
                style { width = matchParent }
                text = item.passenger.fullName
            }

            horizontalLayout {
                matchParentWidth()
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Email / Phone", false)
                    theme.text(this) { text = item.passenger.email }
                    verticalLayout {
                        style { width = weightOf(1) }
                        theme.label(this, "Other phone numbers", false)
                        item.phoneNumbers.forEach {
                            passengerPhoneNumberView(it)
                        }
                    }
                }
                horizontalFiller(8)
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Created", false)
                    theme.text(this) { text = item.passenger.createdOn?.toDate()?.formatDateAndTime() ?: "-" }
                }
            }
            theme.label(this, "Notes", false)
            theme.text(this) { text = item.passenger.notes ?: "-" }

            onClick = {
                popup.dismissDialog()
                val callingCode = phoneEditableView.code.removePrefix("+")
                val phone = phoneEditableView.phoneNumber
                onPassengerSelected(item, PhoneNumberDto(callingCode, phone))
            }
        }
    }

    private fun LinearLayout.passengerPhoneNumberView(phoneNumber: PassengerPhoneNumberDto)  = horizontalLayout {
        style {
            alignItems = Alignment.Center
        }
        theme.text(this) {
            text = phoneNumber.callingCode + phoneNumber.phone
            if(!phoneNumber.isActive) {
                style {
                    color = AppColors.borderColor
                }
            }
        }
        if (phoneNumber.type.enum() == PhoneNumberType.WHATSAPP)
        imageView {
            style {
                alignSelf = Alignment.Center
                width = 24.px
                height = 24.px
                padding = 6.px
             }
            element.src = "/public/img/whatsapp.png"
        }
    }

    private fun onRegisterPassengerClicked() {
        val passengerRequestDto = passengerRegistrationForm?.validateAndGet() ?: return
        viewModel.registerPatient(passengerRequestDto)
    }
}