package com.narbase.letsgo.web.routing.domain.admin.drivertargets

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.drivertargets.ExtendedTargetDto
import com.narbase.letsgo.web.models.TargetsStatus

object GetTargetsEndpoint : EndPoint<GetTargetsEndpoint.Request, GetTargetsEndpoint.Response>() {
    class Request(
        val pageNo: Int,
        val pageSize: Int,
        val targetStatus: DtoName<TargetsStatus>?,
    )

    class Response(
        val extendedTargets: Array<ExtendedTargetDto>,
        val total: Int
    )
}