package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint

object CompleteRideAdminEndpoint
    : EndPoint<CompleteRideAdminEndpoint.Request, CompleteRideAdminEndpoint.Response>() {
    class Request(
        val rideId: Long,
    )

    class Response()
}