package com.narbase.letsgo.web.views.incentives

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.config.ConfigDto
import com.narbase.letsgo.web.dto.config.ExtendedCurrencyIncentiveConfigDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.currencyincentiveconfigs.UpsertCurrencyIncentiveConfigsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertConfigEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class IncentiveConfigViewModel {
    val updateUiState = Observable<BasicUiState>()
    fun updateConfigs(list: List<ExtendedCurrencyIncentiveConfigDto>) {
        basicNetworkCall(updateUiState) {
            val dto = UpsertCurrencyIncentiveConfigsEndpoint.Request(list.toTypedArray())
            val response = UpsertCurrencyIncentiveConfigsEndpoint.remoteProcess(dto).data
        }
    }
}