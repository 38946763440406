package com.narbase.letsgo.web.dto.config

class CallCenterConfigDto (
    val phoneNumber: String,
    val locationRecencyInMins: Int,
    val sipExtension: SipExtensionDto
)


class SipExtensionDto(
    val extension: String,
    val domain: String
)
