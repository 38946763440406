package com.narbase.letsgo.web.dto.passengers

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.models.PhoneNumberType

class PassengerPhoneNumberDto(
    val id: Long? = null,
    val passengerId: Long,
    val callingCode: String,
    val phone: String,
    val type: DtoName<PhoneNumberType>,
    val isActive: Boolean,
    val createdOn: DateTimeDto?,
)
