package com.narbase.letsgo.web.routing.domain.admin.drivertargets

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.drivertargets.TargetDto

object UpsertTargetEndpoint : EndPoint<UpsertTargetEndpoint.Request, UpsertTargetEndpoint.Response>() {
    class Request(
        val target: TargetDto,
        val isAutoRepeatChanged: Boolean
    )
    class Response()
}