package com.narbase.letsgo.web.views.driverTargets

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.dto.dto
import com.narbase.letsgo.web.models.TargetsStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.table.headerCell
import com.narbase.letsgo.web.utils.table.listTable
import com.narbase.letsgo.web.utils.table.tableCell
import com.narbase.letsgo.web.utils.table.tableRow
import com.narbase.letsgo.web.utils.verticalSeparator
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.driverTargets.targetConfig.TargetConfigDialog
import com.narbase.letsgo.web.views.incentives.TimeoutIncentiveConfigDialog

class TargetsManagementComponent : Component() {

    private var paginationControls: PaginationControls? = null
    private val viewModel = TargetsManagementViewModel()

    private var listTableBody: View? = null
    private var targetsStatusDropDownList: RemoteDropDownList<TargetsStatus>? = null

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getTargets()
    }

    private fun onListLoaded() {
        paginationControls?.update(viewModel.pageNo, viewModel.pageSize, viewModel.total)
        listTableBody?.clearAllChildren()
        listTableBody?.apply {
            viewModel.targetsList.forEachIndexed { index, item ->
                val amount = item.target.amount?.toString() ?: "-"
                val budget = item.target.budget?.toString() ?: "-"
                tableRow {
                    tableCell(item.driverProfileGroup.name, 1, 14.px)
                    tableCell(item.target.noOfRides.toString(), 1, 14.px)
                    tableCell(item.target.prizeType.toString(), 1, 14.px)
                    tableCell(amount, 1, 14.px)
                    tableCell(budget, 1, 14.px)


                    onClick = {
                        UpsertTargetDialog(item, onUpdated = {
                            viewModel.getTargets()
                        }).show()
                    }
                }

                if (index != viewModel.targetsList.lastIndex) {
                    verticalSeparator()
                }
            }
        }
    }

    override fun View?.getView() = view {
        id = "TargetManagementRootView"
        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }

                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Targets Management".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()
                    theme.menuButton(this) {
                        text = "Target Settings"
                        onClick = {
                            TargetConfigDialog().show()
                        }
                    }

                    horizontalFiller(20)
                    addItemButton()

                }

                horizontalLayout {
                    style {
                        width = matchParent
                        marginTop = 16.px
                    }
                    horizontalFiller()
                    targetsStatusDropDownList = setupDropDownList(
                        "Status",
                        getList = { _, _, _ ->
                            TargetsStatus.values()
                        },
                        itemToString = { it.dtoName },
                        onItemSelected = {
                            viewModel.targetsStatus = it?.dto()
                            viewModel.getTargets()
                        },
                        defaultItem = TargetsStatus.Ongoing,
                        viewWidthFactory = { matchParent },
                        showAutoComplete = true,
                        rootStyle = style {
                            width = 30.percent
                        }
                    )
                    horizontalFiller(10)
                    searchTextInput("Search".localized()) {
                        viewModel.searchFor(it)
                    }
                }

                verticalLayout {
                    matchParentDimensions()
                    withLoadingAndError(viewModel.getTargetsUiState,
                        onRetryClicked = { viewModel.getTargets() },
                        onLoaded = { onListLoaded() })

                    listTableBody = listTable {
                        headerCell("Profile Group".localized(), 1)
                        headerCell("Number of rides".localized(), 1)
                        headerCell("Prize Type".localized(), 1)
                        headerCell("Amount".localized(), 1)
                        headerCell("Budget".localized(), 1)

                    }
                    paginationControls = setupPaginationControls(viewModel::getNextPage, viewModel::getPreviousPage)
                }
            }
        }
    }
    private fun LinearLayout.addItemButton() {
        theme.mainButton(this) {
            id = "AddGroupButton"
            onClick = {
                UpsertTargetDialog(null, onUpdated = {
                    viewModel.getTargets()
                }).show()
            }
            text = "+ Add new Target"
        }
    }
}