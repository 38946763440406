package com.narbase.letsgo.router


interface CallInterceptor {
    val key: String
}

object IpLockInterceptor : CallInterceptor {
    override val key: String = this::class.simpleName!!
}


object OtpRateLimierInterceptor : CallInterceptor {
    override val key: String = this::class.simpleName!!
}