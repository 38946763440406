package com.narbase.letsgo.web.views.operationSetting

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.config.CallCenterConfigDto
import com.narbase.letsgo.web.dto.config.ConfigDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.incentives.GetConfigEndpoint
import com.narbase.letsgo.web.routing.domain.admin.incentives.UpsertConfigEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class OperationSettingManagementViewModel {

    val upsertUiState = Observable<BasicUiState>()
    val getConfigUiState = Observable<BasicUiState>()

    var config : ConfigDto? = null
    var callCenterConfigDto : CallCenterConfigDto? = null


    var pageNo = 0
    var pageSize = 20
    var total = 0
    fun getConfig(){
        basicNetworkCall(getConfigUiState) {
            val dto = GetConfigEndpoint.Request()
            val response = GetConfigEndpoint.remoteProcess(dto).data
            config = response.data
            callCenterConfigDto = response.data?.callCenterConfig
        }
    }

    fun editItem(callCenterConfig: CallCenterConfigDto) {
        val configDto = config ?: return
        val updateConfig = ConfigDto(
            callCenterConfig = callCenterConfig,
            orderingIncentiveConfig = configDto.orderingIncentiveConfig,
            timeoutIncentiveConfig = configDto.timeoutIncentiveConfig,
            ipLockingConfig = configDto.ipLockingConfig,
            targetConfig = configDto.targetConfig)
        basicNetworkCall(upsertUiState) {
            val dto = UpsertConfigEndpoint.Request(updateConfig)
            val response = UpsertConfigEndpoint.remoteProcess(dto).data
        }
    }

    fun setUpsertUiStateToNull() {
        upsertUiState.value = null
    }
}