package com.narbase.letsgo.web.routing.domain.admin.passengers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.dto.passengers.PhoneNumberAvailabilityDto

object UpsertPassengerPhoneNumbersEndpoint : EndPoint<UpsertPassengerPhoneNumbersEndpoint.Request, UpsertPassengerPhoneNumbersEndpoint.Response>() {
    class Request(
        val phoneNumbers: Array<PassengerPhoneNumberDto>,
    )

    class Response(
        val phoneNumberAvailabilityDto: PhoneNumberAvailabilityDto
    )
}