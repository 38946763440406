package com.narbase.letsgo.web.views.callCenter.selectedpassenger

import com.narbase.kunafa.core.components.TextView
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.models.PhoneNumberAvailabilityStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.showConfirmationDialogWithRemoteAction
import com.narbase.letsgo.web.utils.views.withLoadingAndError

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2024/10/31.
 */
class EditPhoneNumbersDialog(
    private val passengerPhoneNumbers: List<PassengerPhoneNumberDto>,
    val passengerId: Long,
    val onEdited: () -> Unit
) {
    private val popup by lazy { popUpDialog() }
    private val viewModel = EditPhoneNumbersViewModel()
    val addNewUiState = Observable<BasicUiState>()

    private val phoneNumberFields: MutableList<EditPassengerPhoneNumberComponent> =
        passengerPhoneNumbers.map {
            EditPassengerPhoneNumberComponent(it, passengerId, isInvalid = {
                onInvalid(it)
            }, onRemove = { editableComponent ->
                val phoneNumberId = editableComponent.phoneNumber?.id
                if(phoneNumberId != null){
                    deletePhoneNumber(phoneNumberId)
                    onRemoved(editableComponent)
                }

            })
        }.toMutableList()

    var errorTextView: TextView? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Save".localized()
                        onClick = {
                            save()
                        }
                    }.withLoadingAndError(viewModel.saveRideUiState, onLoaded = {
                        if (viewModel.phoneNumberAvailability?.availabilityStatus?.enum() == PhoneNumberAvailabilityStatus.Unavailable) {
                            val unavailablePhoneNumber =
                                viewModel.phoneNumberAvailability?.unavailablePhoneNumber ?: return@withLoadingAndError
                            val existingPassenger =
                                viewModel.phoneNumberAvailability?.phoneNumberOwner ?: return@withLoadingAndError
                            showUnAvailablePhoneNumberError(unavailablePhoneNumber, existingPassenger)

                        } else {
                            onEdited.invoke()
                            popup.dismissDialog()
                        }

                    }, onRetryClicked = {
                        save()
                    })

                }
            }

            verticalLayout {

                style { width = matchParent }
                horizontalLayout {
                    matchParentWidth()
                    theme.bigTextView(this) {
                        text = "Other Phone Numbers".localized()
                    }
                    horizontalFiller()
                    theme.outlineButton(this) {
                        text = "+ Add".localized()
                        onClick = {
                            addNewPhoneField()
                        }
                    }
                }

                verticalFiller(16)

                verticalLayout {
                    matchParentWidth()

                    phoneNumberFields.forEach {
                        mount(it)
                    }


                    withLoadingAndError(addNewUiState,
                        onLoaded = {
                            clearAllChildren()
                            phoneNumberFields.forEach {
                                mount(it)
                            }
                            addNewUiState.value = null
                        },
                        onRetryClicked = {})
                }

                errorTextView = theme.errorText(this) {
                }

                verticalFiller(15)

            }

        }

    }

    private fun showUnAvailablePhoneNumberError(
        phoneNumberDto: PassengerPhoneNumberDto,
        extendedPassengerDto: ExtendedPassengerDto
    ) {
        val errorMessage =
            "Phone number already belongs to another passenger\nOwner: ${extendedPassengerDto.passenger.fullName}".localized()
        errorTextView?.text = errorMessage


    }

    private fun addNewPhoneField() {
        phoneNumberFields.add(EditPassengerPhoneNumberComponent(null, passengerId, isInvalid = {
            onInvalid(it)
        }, onRemove = { editableComponent ->
            onRemoved(editableComponent)
        }))
        addNewUiState.value = BasicUiState.Loaded
    }

    private fun onInvalid(errorMessage: String) {
        errorTextView?.text = errorMessage
    }

    private fun onRemoved(editableComponent: EditPassengerPhoneNumberComponent){
        phoneNumberFields.remove(editableComponent)
        addNewUiState.value = BasicUiState.Loaded
    }

    private fun hasDuplicatePhoneNumbers(phoneNumbers: List<PassengerPhoneNumberDto>): Boolean {
        return phoneNumbers.size != phoneNumbers.distinctBy { it.callingCode + it.phone }.size
    }


    fun save() {
        val p = phoneNumberFields.map {
            val phoneNumber = it.getInfo() ?: return
            phoneNumber
        }
        if (hasDuplicatePhoneNumbers(p)) {
            onInvalid("Phone numbers are duplicates".localized())
            return
        }
        viewModel.savePhoneNumbers(p)
    }

    fun deletePhoneNumber(phoneNumberId: Long) {
        showConfirmationDialogWithRemoteAction(
            "Delete Phone Number".localized(),
            "Are you sure you want to delete this phone number?\nThis action cannot be reversed.".localized(),
            actionButtonText = "Delete".localized(),
            action = {
                viewModel.deletePhoneNumber(phoneNumberId)
            },
            uiState = viewModel.deletePhoneNumberUiState,
            onConfirmed = {},
            actionButtonStyle = null,

        )
    }
}