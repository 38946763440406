package com.narbase.letsgo.web.views.admin.staff

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.dto.core.KmmLong
import com.narbase.letsgo.web.common.data.AuthorizedRole
import com.narbase.letsgo.web.dto.admin.AdminUpsertDto
import com.narbase.letsgo.web.dto.admin.ExtendedAdminDto
import com.narbase.letsgo.web.network.UnknownErrorException
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.staff.GetAdminsEndpoint
import com.narbase.letsgo.web.routing.domain.admin.staff.UpsertAdminsEndpoint
import com.narbase.letsgo.web.utils.BasicUiState
import com.narbase.letsgo.web.utils.DataResponse

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/28.
 */
class StaffManagementViewModel {

    private var filterList = listOf(AuthorizedRole.Admin, AuthorizedRole.Doctor, AuthorizedRole.Receptionist)
    private var searchTerm = ""
    val uiState = Observable<BasicUiState>()
    val upsertUiState = Observable<BasicUiState>()

    var data: List<ExtendedAdminDto> = listOf()

    var pageNo = 0
    var pageSize = 20
    var total = 0

    fun getStaff() {
        upsertUiState.value = null
        basicNetworkCall(uiState) {
            val dto = GetAdminsEndpoint.Request(
                    pageNo, pageSize, searchTerm
            )

            val response = GetAdminsEndpoint.remoteProcess(dto).data
            data = response.list.toList()
            total = response.total
        }
    }

    fun filterStaffBy(list: List<AuthorizedRole>) {
        pageNo = 0
        filterList = list
        uiState.value = BasicUiState.Loaded
        getStaff()
    }

    fun searchFor(term: String) {
        pageNo = 0
        searchTerm = term
        uiState.value = BasicUiState.Loaded
        getStaff()
    }

    fun getNextPage() {
        pageNo++
        getStaff()
    }

    fun getPreviousPage() {
        pageNo--
        getStaff()
    }

    fun addStaffMember(admin: AdminUpsertDto, roleIds: Array<KmmLong>, isIpLockAuthenticated: Boolean) {
        basicNetworkCall(upsertUiState) {
            val dto = UpsertAdminsEndpoint.Request(admin, roleIds, isIpLockAuthenticated)
            val response = UpsertAdminsEndpoint.remoteProcess(dto)
            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
                throw UnknownErrorException()
            }
        }

    }

    fun editStaffMember(admin: AdminUpsertDto, roleIds: Array<KmmLong>, isIpLockAuthenticated: Boolean) {
        basicNetworkCall(upsertUiState) {
            val dto = UpsertAdminsEndpoint.Request(admin, roleIds, isIpLockAuthenticated)
            val response = UpsertAdminsEndpoint.remoteProcess(dto)
            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
                throw UnknownErrorException()
            }
        }

    }

//    fun setUserActive(staffId: String, isActive: Boolean) {
//        basicNetworkCall(userActiveUiState) {
//            val dto = EnableUserDto.RequestDto(staffId, isActive)
//            val response = AdminStaffServerCaller.setUserActive(dto)
//            if (response.status != "${DataResponse.BASIC_SUCCESS}") {
//                throw UnknownErrorException()
//            }
//        }
//
//    }
}
