package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByIdEndpoint
import com.narbase.letsgo.web.utils.BasicUiState


class RidesManagementViewModel {
    val getRideUiState = Observable<BasicUiState>()
    var selectedRide: ExtendedRideDto? = null

    fun getRide(rideId: Long) {
        basicNetworkCall(getRideUiState) {
            val dto = GetRideByIdEndpoint.Request(rideId = rideId)
            val response = GetRideByIdEndpoint.remoteProcess(dto).data
            selectedRide = response.extendedRide
        }
    }

    fun clearSelectedRide() {
        selectedRide = null
    }
}