package com.narbase.letsgo.web.views.driverTargets.targetConfig

import com.narbase.kunafa.core.components.TextInput
import com.narbase.kunafa.core.components.horizontalLayout
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.components.verticalLayout
import com.narbase.kunafa.core.css.width
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.letsgo.web.dto.config.TargetConfigDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.popUpDialog
import com.narbase.letsgo.web.utils.views.withLoadingAndError

class TargetConfigDialog {
    private val viewModel = TargetConfigViewModel()
    private val popup by lazy { popUpDialog() }
    var newTargetMessageInput: TextInput? = null
    var prizeMessageInput: TextInput? = null


    fun show() {
        theme.showDialog(popup) {
            viewModel.getConfig()
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = {
                            popup.dismissDialog()
                        }
                    }
                    horizontalFiller(20)
                    theme.mainButton(this) {
                        text = "Save".localized()
                        onClick = {
                            save()
                        }
                    }.withLoadingAndError(viewModel.updateUiState, onLoaded = {
                        popup.dismissDialog()
                    }, onRetryClicked = {
                        save()
                    })
                }
            }
            verticalLayout {
                style {
                    width = matchParent
                }
                theme.mediumTextView(this) {
                    text = "Target Settings"
                }
                withLoadingAndError(viewModel.getConfigUiState, onLoaded = {
                    targetConfigLayout()
                }, onRetryClicked = {
                    viewModel.getConfig()
                })

            }
        }
    }

    fun LinearLayout.targetConfigLayout() = verticalLayout {
        style {
            width = matchParent
//            padding = 5.px
        }
        verticalLayout {
            style {
                width = matchParent
            }
            theme.label(this, "New Target Message".localized(), false)
            newTargetMessageInput = theme.textInput(this) {
                style {
                    width = matchParent
                }
                placeholder = ""
                text = viewModel.targetConfig?.newTargetMessage ?: ""
            }
        }
        verticalLayout {
            style {
                width = matchParent
            }
            theme.label(this, "Prize Won Message", false)
            prizeMessageInput = theme.textInput(this) {
                style {
                    width = matchParent
                }
                placeholder = ""
                text = viewModel.targetConfig?.prizeMessage ?: ""
            }
        }

    }

    fun save() {
        val newTargetMessage = newTargetMessageInput?.text
        val prizeMessage = prizeMessageInput?.text
        val targetConfig = TargetConfigDto(
            newTargetMessage = newTargetMessage,
            prizeMessage = prizeMessage
        )
        viewModel.upsertConfig(targetConfig)

    }
}