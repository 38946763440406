package com.narbase.letsgo.web.views.admin

import com.narbase.letsgo.models.roles.AdminPrivilege
import com.narbase.letsgo.web.BasePageViewModel
import com.narbase.letsgo.web.dto.admin.AdminPrivilegeListDto
import com.narbase.letsgo.web.translations.localized

/**
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2020/01/19.
 */
class AdminTabs(
    val routeDetails: BasePageViewModel.RouteDetails,
    val privileges: Array<AdminPrivilege> = arrayOf()
) {
    ;

    companion object {

        val Staff = AdminTabs(BasePageViewModel.RouteDetails("/staff", "Current Staff".localized()), arrayOf(AdminPrivilege.StaffManagement))
        val Drivers = AdminTabs(BasePageViewModel.RouteDetails("/drivers", "Drivers Management".localized()), arrayOf(AdminPrivilege.DriversManagement))
        val Rides = AdminTabs(BasePageViewModel.RouteDetails("/rides", "Rides".localized()), arrayOf(AdminPrivilege.RidesAccess))

        //        val InActiveStaff = AdminTabs(BasePageViewModel.RouteDetails("/inactive_staff", "Inactive Staff".localized()))
        val Roles = AdminTabs(BasePageViewModel.RouteDetails("/roles", "Roles".localized()), arrayOf(AdminPrivilege.DynamicRoles))
        val AuditLog = AdminTabs(BasePageViewModel.RouteDetails("/audit_log", "Audit log".localized()), arrayOf(AdminPrivilege.AuditLogAccess))
        val DriverProfileGroups =
            AdminTabs(BasePageViewModel.RouteDetails("/driver_profile_groups", "Driver Profile Groups".localized()), arrayOf(AdminPrivilege.DriverProfileGroups))
        val CallCenter = AdminTabs(BasePageViewModel.RouteDetails("/call_center", "Call Center".localized()), arrayOf(AdminPrivilege.CallCenterOperation))
        val PassengerProfileGroups = AdminTabs(
            BasePageViewModel.RouteDetails(
                "/passenger_profile_groups",
                "Passenger Profile Groups".localized()
            ),
            arrayOf(AdminPrivilege.PassengerProfileGroups)
        )
        val Incentive = AdminTabs(BasePageViewModel.RouteDetails("/incentives", "Incentives".localized()), arrayOf())
        val Targets = AdminTabs(BasePageViewModel.RouteDetails("/targets", "Targets".localized()),  arrayOf(AdminPrivilege.DriverTargets))
        val OperationSetting = AdminTabs(BasePageViewModel.RouteDetails("/operation_setting", "Operation Settings".localized()), arrayOf(AdminPrivilege.OperationSettings))

        val subLists = listOf(
            AdminTabSubList("Staff Management".localized(), listOf(Staff, /*InActiveStaff,*/ Roles)),
            AdminTabSubList("Operation".localized(), listOf(Drivers, Rides)),
            AdminTabSubList("Call Center".localized(), listOf(CallCenter)),
            AdminTabSubList("Profile Groups".localized(), listOf(DriverProfileGroups, PassengerProfileGroups, Incentive, Targets)),
            AdminTabSubList("General settings".localized(), listOf(AuditLog, OperationSetting)),
        )
    }
}

class AdminTabSubList(
    val title: String,
    val tabs: List<AdminTabs>
)
