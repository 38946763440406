package com.narbase.letsgo.web.views.callCenter.ridephonenumber

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.*
import com.narbase.letsgo.web.views.callCenter.selectedpassenger.EditPhoneNumbersDialog
import com.narbase.letsgo.web.views.user.profile.EditablePhoneNumberView

class RidePhoneNumberComponent(
    val passenger: PassengerDto,
    val defaultPhoneNumber: PhoneNumberDto,
    val onPhoneNumberSelected: (PhoneNumberDto) -> Unit

) : Component() {


    private var phoneTextView = TextView()
    val viewModel = RidePhoneNumberViewModel(passenger)

    val popup by lazy {
        popUpDialog(
            onDismissed = {}
        )
    }

    private var selectedPhoneNumber : Observable<PhoneNumberDto> =  Observable()


    override fun View?.getView() = verticalLayout {
        style {
            matchParentWidth()
        }
        selectedPhoneNumber.value = defaultPhoneNumber

        theme.label(this,"Ride phone number: ", false)
        horizontalLayout {
            matchParentWidth()
            verticalLayout {
                selectedPhoneNumber.observe {
                    clearAllChildren()
                    phoneTextView = theme.mediumTextView(this) {}
                    if (it != null) {
                        setSelectedPassengerPhone(it)

                    }
                }
            }
            horizontalFiller()

            materialIcon("edit") {
                style {
                    fontSize = 16.px
                    color = AppColors.textDarkGrey
                    marginEnd = 6.px
                    alignSelf = Alignment.Center
                    pointerCursor()
                }

                onClick = {
                    viewModel.getPassengerPhoneNumbers()
                }
            }.withLoadingAndError(uiState = viewModel.uiState,
                onLoaded = {
                    val phoneNumbers = viewModel.phoneNumbers
                    if (phoneNumbers != null) {
                        showDialog(phoneNumbers)
                    }
                },
                onRetryClicked = {
                    viewModel.getPassengerPhoneNumbers()
                }
            )
        }
    }

    private fun setSelectedPassengerPhone(selectedPhone: PhoneNumberDto) {
        phoneTextView.text = "${selectedPhone.callingCode} ${selectedPhone.phone}"
    }

    fun showDialog(phoneNumbers: List<PassengerPhoneNumberDto>) {
        EditPhoneNumbersDialog(
            passengerPhoneNumbers = phoneNumbers,
            passenger = passenger,
            onEdited = {},
            isSelectable = true,
            onSelected = {phoneNumber ->
                setSelectedPassengerPhone(phoneNumber)
                selectedPhoneNumber.value = phoneNumber
                onPhoneNumberSelected(phoneNumber)

            },
            currentPhoneNumber = selectedPhoneNumber.value
        ).show()

    }
}