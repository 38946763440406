package com.narbase.letsgo.web.routing.domain.admin.rides

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto


object GetRideByBookingKeyEndpoint : EndPoint<GetRideByBookingKeyEndpoint.Request, GetRideByBookingKeyEndpoint.Response>() {
    class Request(
        val bookingKey : String,
    )

    class Response(
        val extendedRide: ExtendedRideDto?,
    )
}