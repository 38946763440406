package com.narbase.letsgo.web.dto.rides

import com.narbase.letsgo.dto.DtoName
import com.narbase.letsgo.dto.core.DateTimeDto
import com.narbase.letsgo.web.dto.ridephonenumber.PhoneNumberDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.models.RideType

class RideDto (
    val id : Long?,
    val carType: String?,
    val destAddress : String?,
    val destLat : Double?,
    val destLng : Double?,
    val pickupAddress : String,
    val pickupLat : Double,
    val pickupLng : Double,
    val notes: String?,
    val price: Int?,
    val discount: Double?,
    val callCenterCost: Double?,
    val ridePromoDiscount: Double?,
    val status: DtoName<RideStatus>,
    val time: DateTimeDto,
    val driverId: Long?,
    val passengerId: Long?,
    val rideType: DtoName<RideType>,
    val rating: Double?,
    val review: String?,
    val bookingKey: String?,
    val statusTimestamp: DateTimeDto?,
    val cancellationReason: String?,
    val senderId: Long?,
    val ridePromoId: Long?,
    val carTypeId: Int?,
    val currencyId: Int?,
    val flagPercentage: Double?,
    val byCallCenter: Long?,
    val distanceInKm: Double?,
    val durationInMin: Double?,
    val reorderOff: Long?,
    val requestedCarTypeId: Int?,
    val ratedByCallCenter: Long?,
    val priceDetails: PriceDetailsDto?,
    val reviewedOn: DateTimeDto?,
    val isQuick: Boolean,
    val isGpsPickupLocation: Boolean
)
class PriceDetailsDto(
    val flagDown: Double?,
    val flagDownCommission: Double?,
    val markup: Double?,
    val markupCommission: Double?,
    val orderingIncentive: Double?,
    val timeoutIncentive: Double?,
    val isTimeoutIncentiveCalculated: Boolean?,
)

class NewAdminRideRequestDto(
    val passengerId : Long,
    val pickupLat: Double,
    val pickupLng: Double,
    val pickupAddress: String,
    val destLat: Double?,
    val destLng: Double?,
    val destAddress: String?,
    val carType: String,
    val carTypeId: Int,
    val time: DateTimeDto?,
    val notes: String,
    val priceAfterDiscount: Int?,
    val fcmToken: String?,
    val priceBeforeDiscount: Int?,
    val senderKey: String?,
    val currencyId: Int?,
    val priceToken: String?,
    val isGpsPickupLocation: Boolean,
    val phoneNumber: PhoneNumberDto
)
