package com.narbase.letsgo.web.theme.main

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.common.AppFontSizes
import com.narbase.letsgo.web.storage.bidirectional
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.views.*
import kotlinx.dom.addClass

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by islam
 * On: 2022/11/08.
 */

object MainTheme {

    fun mainButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                color = Color.white
                backgroundColor = AppColors.main
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.mainDark
                }
                disabled {
                    backgroundColor = AppColors.textInactive
                    cursor = "not-allowed"
                }
            }
            block()
        }
    }

    fun smallMainButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                color = Color.white
                backgroundColor = AppColors.main
                padding = 16.px
                fontSize = 14.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.mainDark
                }
            }
            block()
        }
    }

    fun secondaryButton(parent: View, block: Button.() -> Unit) = outlineButton(parent, block)
    fun smallSecondaryButton(parent: View, block: Button.() -> Unit): Button = smallOutlineButton(parent, block)

    fun outlineButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                backgroundColor = Color.transparent
                color = AppColors.mainDark
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "1px solid ${AppColors.mainDark}"
                hover {
                    backgroundColor = AppColors.secondaryButtonBackground
                }
                disabled {
                    color = AppColors.textInactive
                    border = "1px solid ${AppColors.textInactive}"
                    cursor = "not-allowed"
                }
            }
            block()
        }
    }

    fun unimportantButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                backgroundColor = Color.transparent
                color = AppColors.text
                padding = "8px 12px".dimen()
                fontSize = 16.px
                borderRadius = 4.px
                pointerCursor()
                border = "1px solid ${AppColors.borderColor}"
                hover {
                    backgroundColor = AppColors.secondaryButtonBackground
                }
                disabled {
                    color = AppColors.textInactive
                    border = "1px solid ${AppColors.textInactive}"
                }
            }
            block()
        }
    }
    fun dropDownItem(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                backgroundColor = Color.transparent
                color = AppColors.text
                padding = "8px 12px".dimen()
                fontSize = 14.px
                borderRadius = 4.px
                pointerCursor()
                border = "none"
                hover {
                    backgroundColor = AppColors.secondaryButtonBackground
                }
                disabled {
                    color = AppColors.textInactive
//                    border = "1px solid ${AppColors.textInactive}"
                }
            }
            block()
        }
    }

    fun smallOutlineButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                color = AppColors.mainDark
                backgroundColor = Color.transparent
                padding = 8.px
                fontSize = 14.px
                borderRadius = 4.px
                pointerCursor()
                border = "1px solid ${AppColors.mainDark}"
                hover {
                    backgroundColor = AppColors.secondaryButtonBackground
                }
            }
            block()
        }
    }

    fun menuButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                color = AppColors.text
                backgroundColor = AppColors.white
                padding = "4px 12px".dimen()
                fontSize = 14.px
                borderRadius = 16.px
                pointerCursor()
                border = "1px solid ${AppColors.borderColor}"
                hover {
                    backgroundColor = AppColors.lightBackground
                }
            }
            block()
        }
    }
    fun mainMenuButton(parent: View, block: Button.() -> Unit): Button {
        return parent.button {
            style {
                color = AppColors.white
                backgroundColor = AppColors.main
                padding = "4px 12px".dimen()
                fontSize = 14.px
                borderRadius = 16.px
                pointerCursor()
                border = "1px solid ${AppColors.main}"
                hover {
                    backgroundColor = AppColors.mainDark
                    border = "1px solid ${AppColors.mainDark}"
                }
            }
            block()
        }
    }

    fun textInput(parent: View, block: TextInput.() -> Unit): TextInput {
        return parent.textInput {
            style {
                color = Color.black
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                overflow = "hidden"
                fontSize = 14.px
                padding = 8.px
                focus {
                    border = "1px solid ${AppColors.focusInputBorderColor}"
                }
            }
            block()
        }
    }

    fun textArea(parent: View, block: TextArea.() -> Unit): TextArea {
        return parent.textArea {
            style {
                color = Color.black
                border = "2px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                overflow = "hidden"
                fontSize = 14.px
                padding = 16.px
                focus {
                    border = "2px solid ${AppColors.main}"
                }
            }
            block()
        }
    }


    fun simpleLabel(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                fontSize = 12.px
                color = AppColors.text
                paddingTop = 12.px
                paddingBottom = 8.px
            }
            block()
        }
    }
    fun plainLabel(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                fontSize = 12.px
                color = AppColors.text
            }
            block()
        }
    }


    fun errorText(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                marginTop = 8.px
                width = wrapContent
                height = wrapContent
                fontSize = 14.px
                textAlign = bidirectional(TextAlign.Left, TextAlign.Right)
                color = AppColors.redLight
            }
            block()

        }
    }

    fun textInputWithIcon(
            parent: View,
            iconUrl: String,
            block: TextInputWithIconConfig.() -> Unit
    ): TextInputWithIconViewHolder {
        val config = TextInputWithIconConfig().apply(block)
        var icon: ImageView? = null
        var textInput: TextInput? = null
        val layout = parent.horizontalLayout {
            style {
                alignItems = Alignment.Center
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                overflow = "hidden"
            }
            config.layout?.invoke(this)
            icon = imageView {
                style {
                    alignSelf = Alignment.Center
                    width = 24.px
                    height = 24.px
                    padding = 6.px
                }
                element.src = iconUrl
                config.icon?.invoke(this)
            }
            textInput = textInput {
                style {
                    color = Color.black
                    width = weightOf(1, 200.px)
                    height = wrapContent
                    fontSize = 16.px
                    border = "0px solid #ffffff"
                    borderWidth = "0px"
                    outline = "none"
                    padding = 6.px
                }
                config.textInput?.invoke(this)
            }
        }

        return TextInputWithIconViewHolder(layout, icon!!, textInput!!)
    }

    fun labeledTextInput(
            parent: View,
            label: String,
            isRequired: Boolean = false,
            parentRuleSet: RuleSet? = null,
            block: TextInput.() -> Unit = {}
    ): TextInput {
        var textInput: TextInput? = null
        parent.verticalLayout {
            if (parentRuleSet != null)
                addRuleSet(parentRuleSet) else style {
                width = matchParent
            }
            label(this, label, isRequired = isRequired)
            textInput = this@MainTheme.textInput(this) {
                style {
                    width = matchParent
                }
                block()
            }
        }
        return textInput!!
    }

    fun labeledTextArea(
            parent: View,
            label: String,
            isRequired: Boolean = false,
            parentRuleSet: RuleSet? = null,
            block: TextArea.() -> Unit = {}
    ): TextArea {
        var textInput: TextArea? = null
        parent.verticalLayout {
            if (parentRuleSet != null)
                addRuleSet(parentRuleSet) else style {
                width = matchParent
            }
            label(this, label, isRequired = isRequired)
            textInput = this@MainTheme.textArea(this) {
                style {
                    width = matchParent
                }
                block()
            }
        }
        return textInput!!
    }

    fun label(parent: View, label: String, isRequired: Boolean): LinearLayout {
        return parent.horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
            }
            horizontalLayout {
                style {
                    paddingEnd = 8.px
                    position = "relative"
                }
                simpleLabel(this) {
                    text = label
                }
                horizontalFiller(8.px)

                if (isRequired) {
                    redStarTextView(this).apply {
                        style {
                            position = "absolute"
                            end = 8.px
                            top = 10.px
                        }
                    }
                }
            }
        }
    }
    fun wrappedLabel(parent: View, label: String, isRequired: Boolean): LinearLayout {
        return parent.horizontalLayout {
            style {
                width = wrapContent
                height = wrapContent
            }
            horizontalLayout {
                style {
                    paddingEnd = 8.px
                    position = "relative"
                }
                simpleLabel(this) {
                    text = label
                }
                horizontalFiller(8.px)

                if (isRequired) {
                    redStarTextView(this).apply {
                        style {
                            position = "absolute"
                            end = 8.px
                            top = 10.px
                        }
                    }
                }
            }
        }
    }

    fun redStarTextView(parent: View): TextView {
        return parent.textView {
            addClass("red_star_text_view")
            text = "*"
            style {
                fontStyle = "bold"
                fontSize = 12.px
                color = AppColors.redLight
            }
        }
    }

    fun title(parent: View, title: String): LinearLayout {
        return parent.horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
            }
            horizontalLayout {
                style {
                    padding = 8.px
                    position = "relative"
                }
                textView {
                    text = title
                    style {
                        fontSize = AppFontSizes.largeTitle
                        color = AppColors.text
                        padding = 8.px
                        fontWeight = "bold"
                    }
                }
            }
        }
    }

    fun smallSubtitle(parent: View, title: String): LinearLayout {
        return parent.horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
            }
            horizontalLayout {
                style {
                    position = "relative"
                }
                textView {
                    text = title
                    style {
                        fontSize = AppFontSizes.smallText
                        color = AppColors.text
                        fontWeight = "bold"
                    }
                }
            }
        }
    }

    @Deprecated("Use subTitleFit")
    fun subTitle(parent: View, title: String, block: (TextView.() -> Unit)? = null): LinearLayout {
        return parent.horizontalLayout {
            style {
                width = matchParent
                height = wrapContent
            }
            horizontalLayout {
                style {
                    position = "relative"
                }
                textView {
                    text = title
                    style {
                        fontSize = AppFontSizes.normalText
                        color = AppColors.black
                        paddingBottom = 8.px
                        fontWeight = "bold"
                    }
                    block?.invoke(this)
                }
            }
        }
    }

    fun subTitleFit(parent: View, title: String, block: (TextView.() -> Unit)? = null): TextView {
        return parent.textView {
            text = title
            style {
                fontSize = AppFontSizes.normalText
                color = AppColors.black
                paddingBottom = 8.px
                fontWeight = "bold"
            }
            block?.invoke(this)
        }
    }

    fun bigTextView(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                fontSize = AppFontSizes.largerText
                color = AppColors.textDarkest
            }
            block()
        }
    }

    fun mediumTextView(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                fontSize = AppFontSizes.normalText
                color = AppColors.text
            }
            block()
        }
    }

    fun text(parent: View, block: TextView.() -> Unit): TextView {
        return parent.textView {
            style {
                fontSize = 14.px
                color = AppColors.text
            }
            block()
        }
    }

    class ThemeDialogStyle(
            val height: Dimension? = null,
            val minWidth: Dimension? = null,
    )

    fun showDialog(dialog: PopUpDialog, isDismissible: Boolean = true, customStyle: ThemeDialogStyle? = null, block: LinearLayout.(ThemeDialogBuilder) -> Unit) {
        val builder = ThemeDialogBuilder()

        dialog.showDialog(isDismissible) {
            verticalLayout {
                addClass("dialogVerticalLayout")
                style {
                    width = wrapContent
                    height = wrapContent
                    borderRadius = 8.px
                    backgroundColor = Color.white
                    overflow = "hidden"
                    alignItems = Alignment.Center
                }

                horizontalLayout {
                    style {
                        width = wrapContent
                        height = wrapContent
                        minWidth = customStyle?.minWidth ?: 40.vw
                        if (customStyle?.height == null) {
                            maxHeight = 90.vh
                        } else {
                            height = customStyle.height
                            maxHeight = customStyle.height
                        }
                        alignItems = Alignment.Center
                    }

                    verticalLayout {
                        style {
                            width = matchParent
                            alignSelf = Alignment.Stretch
                            alignItems = Alignment.Center
                            overflow = "auto"
                            element.addClass("normalBar")
                        }
                        verticalLayout {
                            style {
                                alignSelf = Alignment.Stretch
                                height = wrapContent
                                padding = 16.px
                            }

                            block(builder)
                        }
                    }

                }
                horizontalLayout {
                    style {
                        width = matchParent
                        padding = 16.px
                        backgroundColor = AppColors.lightBackground
                        borderTop = "1px solid ${AppColors.borderColor}"
                    }
                    isVisible = builder.bottomBar != null

                    builder.bottomBar?.invoke(this)
                }
            }

        }
    }

    class ThemeDialogBuilder {
        var bottomBar: (LinearLayout.() -> Unit)? = null
    }

    class TextInputWithIconViewHolder(val layout: LinearLayout, val icon: ImageView, val textInput: TextInput)
    class TextInputWithIconConfig {

        var layout: (LinearLayout.() -> Unit)? = null
        var icon: (ImageView.() -> Unit)? = null
        var textInput: (TextInput.() -> Unit)? = null

        fun layout(block: LinearLayout.() -> Unit) {
            layout = block
        }

        fun icon(block: ImageView.() -> Unit) {
            icon = block
        }

        fun textInput(block: TextInput.() -> Unit) {
            textInput = block
        }
    }

    val dropDownListStyle by lazy {
        classRuleSet {
            color = Color.black
            width = matchParent
            border = "2px solid ${AppColors.borderColor}"
            borderRadius = 4.px
            overflow = "hidden"
            fontSize = 14.px
            padding = "8px 2px".dimen()
            pointerCursor()
            alignItems = Alignment.Center
        }
    }
    val standardBorder = "1px solid ${AppColors.borderColor}"

}


