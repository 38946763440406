package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.models.RideStatus
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.roundToTwoDigits
import com.narbase.letsgo.web.utils.toHoursAndMinutes
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.matchParentWidth
import com.narbase.letsgo.web.utils.views.showConfirmationDialogWithRemoteAction
import com.narbase.letsgo.web.views.callCenter.RidePriceDisplayItem
import com.narbase.letsgo.web.views.callCenter.cancelride.CancelRideDialog


class SelectedRideComponent(
    val extendedRideDto: ExtendedRideDto,
    val onRideChanged: () -> Unit
) : Component(
) {
    val viewModel = SelectedRideViewModel()


    override fun View?.getView() = horizontalLayout {
        style {
            width = matchParent
        }

        verticalLayout {
            style {
                width = matchParent
                padding = 24.px
            }
            rideDetailsLayout(extendedRideDto)
        }
    }

    private fun LinearLayout.rideDetailsLayout(item: ExtendedRideDto) = verticalLayout {
        style {
            width = 80.percent
        }
        horizontalLayout {
            style {
                alignItems = Alignment.Center
            }
            matchParentWidth()
            theme.text(this) {
                style {
                    width = wrapContent
                }
                text = item.ride.bookingKey.toString()
            }
            horizontalFiller()
            actionButtonsLayout()

        }

        verticalFiller(16)
        horizontalLayout {
            style {
                width = matchParent
                paddingEnd = 30.px
            }
            verticalLayout {
                style { weightOf(1) }
                theme.label(this, "PickUp: ".localized(), false)
                theme.mediumTextView(this) {
                    text = item.ride.pickupAddress
                }

                verticalLayout {
                    style { weightOf(1) }
                    theme.label(this, "Distance: ".localized(), false)
                    theme.mediumTextView(this) {
                        text = item.ride.distanceInKm?.let { "${it.roundToTwoDigits()} Km" } ?: ""
                    }
                }
            }
            horizontalFiller()
            verticalLayout {
                style { weightOf(1) }
                theme.label(this, "Destination: ".localized(), false)
                theme.mediumTextView(this) {
                    text = item.ride.destAddress ?: "Open Ride".localized()
                }
                verticalLayout {
                    style { weightOf(1) }
                    theme.label(this, "Duration: ".localized(), false)
                    theme.mediumTextView(this) {
                        text = item.ride.durationInMin?.let { "${it.roundToTwoDigits()} mins" } ?: ""
                    }
                }
            }

        }
        theme.label(this, "Time: ".localized(), false)

        horizontalLayout {
            theme.mediumTextView(this) {
                text = item.ride.time.toDate().toDateString()
            }
            horizontalFiller(20)
            theme.mediumTextView(this) {
                text = item.ride.time.toDate().toHoursAndMinutes()
            }
        }

        theme.label(this, "Status: ".localized(), false)
        theme.mediumTextView(this) {
            text = item.ride.status.enum().dtoName
        }
        verticalFiller(5)
        mount(RidePriceDisplayItem(item, "Price Details"))

        if (item.ride.status.enum() == RideStatus.cancelled) {
            theme.label(this, "Cancellation Reason: ".localized(), false)
            theme.mediumTextView(this) {
                text = item.ride.cancellationReason ?: "none"
            }
        }
        theme.label(this, "Note: ".localized(), false)
        theme.mediumTextView(this) {
            text = item.ride.notes ?: "none"
        }
        verticalLayout {
            theme.label(this, "Driver: ".localized(), false)
            theme.mediumTextView(this) {
                text = item.driver?.fullName ?: ""
            }
            theme.mediumTextView(this) {
                text = item.driver?.phone ?: ""
            }
            theme.mediumTextView(this) {
                text = item.driver?.licenseNumber ?: ""
            }
        }
    }

    private fun LinearLayout.actionButtonsLayout() = horizontalLayout {
        theme.unimportantButton(this) {
            isVisible = extendedRideDto.ride.status.enum() !in RideStatus.driverCannotTerminateStatuses()
            text = "Cancel Ride".localized()
            onClick = {
                extendedRideDto.ride.id?.let { rideId -> CancelRideDialog(rideId = rideId, onRideCancelled = onRideChanged).show() }
            }
        }
        horizontalFiller(20)
        theme.unimportantButton(this) {
            isVisible = extendedRideDto.ride.status.enum() !in RideStatus.driverCannotTerminateStatuses()
            text = "Complete Ride".localized()
            onClick = {
                extendedRideDto.ride.id?.let { rideId -> completeRideConfirmationDialog(rideId)}
            }
        }
    }


    private fun completeRideConfirmationDialog(rideId: Long) {
        showConfirmationDialogWithRemoteAction(
            "Complete Ride".localized(),
            message = "Are you sure you want to complete this ride?".localized(),
            actionButtonText = "Complete ride".localized(),
            dismissButtonText = null,
            onConfirmed = {
                onRideChanged.invoke()
            },
            uiState = viewModel.completeRideUiState,
            action = { viewModel.completeRide(rideId) },
            actionButtonStyle = null
        )
    }


}
