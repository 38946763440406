package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.weightOf
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.drawable.Color
import com.narbase.letsgo.dto.enum
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.datetime.toDate
import com.narbase.letsgo.web.utils.formatDateAndTime
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*

class RideSelectorDialog(
    private val onRideSelected: (rideId: Long) -> Unit
) {
    private var searchByBookingKeyLayout: LinearLayout? = null
    private val popup by lazy { popUpDialog() }
    private val viewModel = RideSelectorViewModel()
    private var bookingKeyInput : TextInput? = null

    fun show() {
        theme.showDialog(popup) {
            it.bottomBar = {
                horizontalLayout {
                    matchParentWidth()
                    horizontalFiller()
                    theme.unimportantButton(this) {
                        text = "Dismiss".localized()
                        onClick = { popup.dismissDialog() }
                    }

                }
            }

            theme.bigTextView(this) {
                text = "Find a ride".localized()
            }
            verticalFiller(4)
            theme.text(this) {
                text = "Search rides using booking key".localized()
            }
            verticalFiller(16)
            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    backgroundColor = Color.white
                    alignItems = Alignment.Center
                }
                searchByBookingKeyLayout = verticalLayout {
                    matchParentWidth()

                    theme.label(this, "Booking key".localized(), true)
                    form {
                        element.onsubmit = {
                            it.preventDefault()
                        }
                        style {
                            matchParentWidth()
                        }
                        horizontalLayout {
                            style {
                                width = matchParent
                            }

                            bookingKeyInput = theme.textInput(this) {
                                placeholder = "booking key"
                            }

                            horizontalFiller()

                            theme.menuButton(this) {
                                element.type = "submit"
                                horizontalLayout {
                                    style {
                                        alignSelf = Alignment.Center
                                        padding = 4.px
                                    }
                                    imageView {
                                        style {
                                            alignSelf = Alignment.Center
                                            width = 12.px
                                            height = 12.px
                                        }
                                        element.src = "/public/img/search.png"
                                    }
                                    horizontalFiller(8)
                                    textView {
                                        text = "Search Rides".localized()
                                    }
                                }
                                onClick = {
                                    bookingKeyInput?.text?.let { bookingKey -> viewModel.searchFor(bookingKey) }
                                }
                            }
                        }
                    }
                }
                verticalFiller(10)

                verticalLayout {
                    matchParentWidth()
                    withLoadingAndError(
                        viewModel.uiState,
                        onRetryClicked = viewModel::getRide,
                        onLoaded = { onListLoaded() }
                    )
                }
            }

        }
        onDialogCreated()
    }

    private fun onDialogCreated() {
        bookingKeyInput?.element?.focus()
    }

    private fun View.onListLoaded() {
        clearAllChildren()
        val foundItem = viewModel.data
        if (foundItem == null) {
            searchByBookingKeyLayout?.isVisible = false
            textView {
                style {
                    width = matchParent
                    paddingHorizontal = 8.px
                    color = AppColors.textLight
                    textAlign = TextAlign.Center
                }
                text = "No ride found".localized()
            }
            verticalFiller(8)

        } else {
            searchByBookingKeyLayout?.isVisible = true
            populateFoundRideView(foundItem)
        }
    }

    private fun View.populateFoundRideView(item: ExtendedRideDto) {
        verticalLayout {
            style {
                width = matchParent
                pointerCursor()
                padding = 8.px
                border = "1px solid ${AppColors.borderColor}"
                borderRadius = 4.px
                hover { backgroundColor = AppColors.lightBackground }
            }
            theme.bigTextView(this) {
                style { width = matchParent }
                text = item.ride.bookingKey ?: ""
            }

            horizontalLayout {
                matchParentWidth()
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Driver", false)
                    theme.text(this) { text = item.driver?.fullName ?: "No driver".localized() }
                }
                horizontalFiller(8)
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Created", false)
                    theme.text(this) { text = item.ride.time.toDate().formatDateAndTime() ?: "-" }
                }
            }
            horizontalLayout {
                matchParentWidth()
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Passenger", false)
                    theme.text(this) { text = item.passenger.fullName }
                }
                horizontalFiller(8)
                verticalLayout {
                    style { width = weightOf(1) }
                    theme.label(this, "Status", false)
                    theme.text(this) { text = item.ride.status.enum().name }
                }
            }

            onClick = {
                popup.dismissDialog()
                val rideId = item.ride.id
                if (rideId != null){
                    onRideSelected(rideId)
                }
            }
        }
    }

}