package com.narbase.letsgo.web.views.operationSetting

import com.narbase.kunafa.core.components.*
import com.narbase.kunafa.core.components.layout.LinearLayout
import com.narbase.kunafa.core.css.*
import com.narbase.kunafa.core.dimensions.dependent.matchParent
import com.narbase.kunafa.core.dimensions.dependent.wrapContent
import com.narbase.kunafa.core.dimensions.percent
import com.narbase.kunafa.core.dimensions.px
import com.narbase.kunafa.core.dimensions.vw
import com.narbase.kunafa.core.drawable.Color
import com.narbase.kunafa.core.lifecycle.LifecycleOwner
import com.narbase.letsgo.web.common.AppColors
import com.narbase.letsgo.web.dto.config.CallCenterConfigDto
import com.narbase.letsgo.web.network.makeNotVisible
import com.narbase.letsgo.web.network.makeVisible
import com.narbase.letsgo.web.theme.theme
import com.narbase.letsgo.web.translations.localized
import com.narbase.letsgo.web.utils.horizontalFiller
import com.narbase.letsgo.web.utils.scrollable.scrollable
import com.narbase.letsgo.web.utils.verticalFiller
import com.narbase.letsgo.web.utils.views.*

class OperationSettingsManagementComponent : Component() {

    private val viewModel = OperationSettingManagementViewModel()
    private val popup by lazy { popUpDialog() }

    override fun onViewMounted(lifecycleOwner: LifecycleOwner) {
        super.onViewMounted(lifecycleOwner)
        viewModel.getConfig()
    }

    override fun View?.getView() = view {
        id = "driverProfileGroupsManagementRootView"

        style {
            matchParentDimensions
        }
        scrollable {
            style {
                matchParentDimensions
            }

            verticalLayout {
                style {
                    width = matchParent
                    height = wrapContent
                    padding = 32.px
                }


                horizontalLayout {
                    style {
                        width = matchParent
                    }
                    textView {
                        text = "Operation Settings".localized()
                        style {
                            width = wrapContent
                            fontSize = 20.px
                            fontWeight = "bold"
                        }
                    }

                    horizontalFiller()


                }
                verticalFiller(20)

                horizontalLayout {
                    style {
                        width = 50.percent
                    }
                    horizontalFiller(20)
                    horizontalLayout {
                        withLoadingAndError(viewModel.getConfigUiState, onRetryClicked = {
                            viewModel.getConfig()
                        }, onLoaded = {
                            clearAllChildren()
                            val phoneNumberInput =
                                theme.labeledTextInput(this, "Call Center Phone Number:".localized(), false)
                            phoneNumberInput.text = viewModel.callCenterConfigDto?.phoneNumber ?: ""
                            phoneNumberInput.element.disabled = true

                        })
                    }
                    horizontalFiller()

                    editCallCenterConfigButton()
                }
            }
        }
    }

    private fun LinearLayout.editCallCenterConfigButton() {
        theme.mainButton(this) {
            id = "editPhoneNumber"
            onClick = {
                upsertDialog(viewModel.callCenterConfigDto)
            }
            text = "Edit"
        }
    }

    private var upsertPhoneNumberField: TextInput? = null

    private fun upsertDialog(dto: CallCenterConfigDto? = null) {
        viewModel.setUpsertUiStateToNull()
        theme.showDialog(popup) {

            verticalLayout {
                style {
                    height = wrapContent
                    width = 70.vw
                    backgroundColor = Color.white
                    padding = 20.px
                    borderRadius = 8.px
                }
                textView {
                    style {
                        fontWeight = "bold"
                        marginBottom = 8.px
                        fontSize = 16.px
                    }
                    text = "Edit Call Center Config".localized()
                }

                val phoneNumberInput = theme.labeledTextInput(this, "Phone Number".localized(), true)
                phoneNumberInput.element.type = "number"
                phoneNumberInput.element.placeholder = ""
                phoneNumberInput.text = dto?.phoneNumber ?: ""

                upsertPhoneNumberField = phoneNumberInput

                verticalFiller(12)
                val errorText = textView {
                    style {
                        marginBottom = 8.px
                        fontSize = 14.px
                        color = AppColors.redLight
                    }
                    isVisible = false
                    text = "Please enter valid fields values".localized()
                }
                verticalFiller(10)

                it.bottomBar = {
                    horizontalLayout {
                        style {
                            width = matchParent
                            height = wrapContent
                            justifyContent = JustifyContent.End
                            marginTop = 10.px
                        }

                        theme.mainButton(this) {

                            text = "Save".localized()
                            onClick = {
                                onSaveButtonClicked(
                                    dto, errorText
                                )
                            }

                            withLoadingAndError(viewModel.upsertUiState, onRetryClicked = {
                                onSaveButtonClicked(
                                    dto, errorText
                                )
                            }, onLoaded = {
                                popup.dismissDialog()
                                viewModel.getConfig()
                            })
                        }

                    }

                }

            }
        }
        upsertPhoneNumberField?.element?.focus()
    }

    private fun onSaveButtonClicked(
        dto: CallCenterConfigDto?, errorText: TextView
    ) {
        val phoneNumberInput = upsertPhoneNumberField ?: return

        if (phoneNumberInput.text.trim().isBlank()) {
            makeVisible(errorText)
            errorText.text = "Please enter a valid phone number".localized()
            return
        }

        makeNotVisible(errorText)
        val phoneNumber = phoneNumberInput.text.trim()

        viewModel.editItem(
            CallCenterConfigDto(
                phoneNumber = phoneNumber
            )
        )
    }
}