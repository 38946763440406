package com.narbase.letsgo.web.routing.domain.admin.passengers.phonenumbers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.PassengerDto

object DeletePassengerPhoneNumberEndpoint : EndPoint<DeletePassengerPhoneNumberEndpoint.Request, DeletePassengerPhoneNumberEndpoint.Response>() {
    class Request(
        val phoneNumberId: Long
    )

    class Response(
    )
}