package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName

enum class PhoneNumberType(dtoName: String? = null) : EnumDtoName {
    WHATSAPP,
    CALLING
    ;

    override val dtoName = dtoName ?: this.name
}