package com.narbase.letsgo.web.routing.domain.admin.passengers

import com.narbase.letsgo.router.EndPoint
import com.narbase.letsgo.web.dto.passengers.ExtendedPassengerDto

object GetPassengerByIdEndpoint : EndPoint<GetPassengerByIdEndpoint.Request, GetPassengerByIdEndpoint.Response>() {
    class Request(
        val id: Long,
    )

    class Response(
        val extendedPassengerDto: ExtendedPassengerDto?,
    )
}