package com.narbase.letsgo.web.views.callCenter.ridephonenumber

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.passengers.PassengerDto
import com.narbase.letsgo.web.dto.passengers.PassengerPhoneNumberDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.passengers.phonenumbers.GetPassengerPhoneNumbersEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

/*
 * NARBASE TECHNOLOGIES CONFIDENTIAL
 * ______________________________
 * [2017] -[2019] Narbase Technologies
 * All Rights Reserved.
 * Created by omnia
 * On: 2025/01/14.
 */
class RidePhoneNumberViewModel (val passenger: PassengerDto) {

    val uiState = Observable<BasicUiState>()
    var phoneNumbers: List<PassengerPhoneNumberDto>? = emptyList()

    fun getPassengerPhoneNumbers() {
        val passengerId = passenger.id ?: return
        basicNetworkCall(uiState) {
            val dto = GetPassengerPhoneNumbersEndpoint.Request(
                passengerId = passengerId,
                pageNo = 0,
                pageSize = 100,


            )
            val response = GetPassengerPhoneNumbersEndpoint.remoteProcess(dto).data
            phoneNumbers = response.phoneNumbers.toList()
        }

    }


}