package com.narbase.letsgo.web.views.rides

import com.narbase.kunafa.core.lifecycle.Observable
import com.narbase.letsgo.web.dto.rides.ExtendedRideDto
import com.narbase.letsgo.web.network.basicNetworkCall
import com.narbase.letsgo.web.network.remoteProcess
import com.narbase.letsgo.web.routing.domain.admin.rides.GetRideByBookingKeyEndpoint
import com.narbase.letsgo.web.utils.BasicUiState

class RideSelectorViewModel {

    private var bookingKey = ""
    val uiState = Observable<BasicUiState>()
    var data: ExtendedRideDto? = null

    fun searchFor(term: String) {
        bookingKey = term
        uiState.value = BasicUiState.Loaded
        getRide()
    }

    fun getRide() {
        basicNetworkCall(uiState) {
            val dto = GetRideByBookingKeyEndpoint.Request(bookingKey = bookingKey)
            val response = GetRideByBookingKeyEndpoint.remoteProcess(dto).data
            data = response.extendedRide
        }
    }
}