package com.narbase.letsgo.web.dto.driverprofilegroup

import com.narbase.letsgo.web.dto.flags.FlagDto

class DriverProfileGroupDto(
    val id: Int?,
    val name: String,
    val commission: Double,
    val isCommissionPercentage: Boolean,
)

class ExtendedDriverProfileGroupDto(
    val driverProfileGroup: DriverProfileGroupDto,
    val flag: FlagDto?
)