package com.narbase.letsgo.web.models

import com.narbase.letsgo.dto.EnumDtoName
enum class TargetsStatus(dtoName: String? = null) : EnumDtoName {
    Upcoming,
    Ongoing,
    Completed,
    AwaitingBudget
    ;

    override val dtoName = dtoName ?: this.name
}